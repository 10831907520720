import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { updateBreadCrumbs } from "../../actions";

import CompleteEventsTable from "./components/CompleteEventsTable";

import "../../styles/eventStyles.scss";

const CompleteEventsPage = props => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      updateBreadCrumbs([
        {
          text: "APN Immersion Day",
          href: "/"
        },
        {
          text: "My Complete Events",
          href: "/events/complete"
        }
      ])
    );
  }, [dispatch]);

  return (
    <>
      <CompleteEventsTable history={props.history} />
    </>
  );
};

export default CompleteEventsPage;
