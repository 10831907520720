import React, { useState, useEffect, useReducer } from "react";
import { useDispatch } from "react-redux";

import { API, graphqlOperation } from "aws-amplify";
import { updatePartner } from "../../../graphql/mutations";
import { getOrganization } from "../../../graphql/apnid-queries";

import { addFlash } from "../../../actions";
import { formReducer } from "../../../reducers";

import {
  Button,
  ColumnLayout,
  Form,
  FormField,
  FormSection,
  Input,
  Spinner,
  TokenGroup
} from "@amzn/awsui-components-react";

const DEFAULT_STATE = {
  fieldValues: {
    name: "",
    headquarters: "",
    coverageAreasInput: "",
    coverageAreas: [],
    specialtyDomainsInput: "",
    specialtyDomains: []
  },
  fieldStates: {
    name: "",
    headquarters: ""
  },
  requiredFields: ["name", "headquarters"]
};

const EditOrganizationDetailsForm = ({ partnerId, history }) => {
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useReducer(formReducer, DEFAULT_STATE);
  const [formErrorText, setFormErrorText] = useState(null);

  // Get data on page load
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const result = await API.graphql(
        graphqlOperation(getOrganization, { id: partnerId })
      );
      let detail = result.data.getPartner;
      if (!detail.headquarters) detail.headquarters = "";
      if (!detail.coverageAreas) detail.coverageAreas = [];
      if (!detail.specialtyDomains) detail.specialtyDomains = [];
      const formInitState = {
        fieldValues: {
          name: detail.name,
          headquarters: detail.headquarters,
          coverageAreasInput: "",
          coverageAreas: detail.coverageAreas.map(val => ({ label: val })),
          specialtyDomainsInput: "",
          specialtyDomains: detail.specialtyDomains.map(val => ({ label: val }))
        },
        fieldStates: {
          name: true,
          headquarters: true
        },
        requiredFields: ["name", "headquarters"]
      };
      setForm({
        type: "initialize",
        payload: formInitState
      });
      setLoading(false);
    };
    fetchData();
  }, [partnerId]);

  const dispatch = useDispatch();

  const formIsValid = () => {
    let formErrors = 0;

    let key;

    for (key in form.fieldStates) {
      let keyIsRequired = form.requiredFields.indexOf(key) > -1 ? true : false;

      if (
        keyIsRequired &&
        (form.fieldStates[key] === false || form.fieldStates[key] === "")
      )
        formErrors += 1;
      if (form.fieldStates[key] === false) formErrors += 1;
    }

    return formErrors === 0 ? true : false;
  };

  const submitForm = async () => {
    let input = { ...form.fieldValues };

    const flattenTokenList = list => {
      let tList = [];
      list.forEach(item => tList.push(item.label));
      return tList;
    };

    // delete unwanted fields
    delete input.coverageAreasInput;
    delete input.specialtyDomainsInput;

    // flatten fields from form
    input.coverageAreas = flattenTokenList(input.coverageAreas);
    input.specialtyDomains = flattenTokenList(input.specialtyDomains);

    // add the partner id
    input.id = partnerId;

    try {
      await API.graphql(
        graphqlOperation(updatePartner, {
          input: input
        })
      );
      history.push("/organization");
    } catch (err) {
      dispatch(
        addFlash({
          type: "error",
          header: "An error occurred.",
          content: err.errors[0].message
        })
      );
    }
  };

  if (loading)
    return (
      <span>
        <Spinner size="large" />
      </span>
    );
  else
    return (
      <div>
        <Form
          header={
            <span>
              <h1 className="awsui-util-d-ib">Edit organization detail</h1>
            </span>
          }
          errorText={formErrorText}
          actions={
            <div>
              <Button
                variant="link"
                onClick={() => history.push(`/organization`)}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  if (formIsValid(form)) {
                    setFormErrorText("");
                    submitForm();
                  } else {
                    setFormErrorText(
                      "Please fill out all of the required form fields."
                    );
                  }
                }}
              >
                Save
              </Button>
            </div>
          }
        >
          <FormSection
            id="partner-sfdc-panel"
            header={<h2>Partner Details</h2>}
          >
            <ColumnLayout>
              <div data-awsui-column-layout-root={true}>
                <FormField
                  label="Company Name"
                  errorText={
                    form.fieldStates.name === "" || form.fieldStates.name
                      ? ""
                      : "This field is required."
                  }
                >
                  <Input
                    id="name"
                    value={form.fieldValues.name}
                    onInput={({ detail }) => {
                      setForm({
                        type: "string",
                        required: true,
                        payload: {
                          field: "name",
                          value: detail.value
                        }
                      });
                    }}
                  />
                </FormField>
                <FormField
                  label="Headquarters Location"
                  errorText={
                    form.fieldStates.headquarters === "" ||
                    form.fieldStates.headquarters
                      ? ""
                      : "This field is required."
                  }
                >
                  <Input
                    id="headquarters"
                    value={form.fieldValues.headquarters}
                    onInput={({ detail }) => {
                      setForm({
                        type: "string",
                        required: true,
                        payload: {
                          field: "headquarters",
                          value: detail.value
                        }
                      });
                    }}
                  />
                </FormField>
              </div>
            </ColumnLayout>
          </FormSection>

          <FormSection
            id="partner-coverage-areas-panel"
            header={<h2>Coverage Areas</h2>}
            description={
              <span>
                Specify the coverage areas of the partner.
                <br />
                A coverage area should include: City, State, Country
                <br />
                Use the <b>ENTER</b> or <b>RETURN</b> key to lock in a value.
                Values are not saved unless they appear in the token group below
                the input box.
              </span>
            }
          >
            <ColumnLayout>
              <div data-awsui-column-layout-root={true}>
                <FormField
                  label={
                    <span>
                      Coverage Areas <i>- optional</i>
                    </span>
                  }
                >
                  <Input
                    id="coverageAreas"
                    value={form.fieldValues.coverageAreasInput}
                    onInput={({ detail }) => {
                      setForm({
                        type: "string",
                        payload: {
                          field: "coverageAreasInput",
                          value: detail.value
                        }
                      });
                    }}
                    onChange={({ detail }) => {
                      setForm({
                        type: "addToken",
                        validation: "required",
                        payload: {
                          field: "coverageAreas",
                          value: detail.value
                        }
                      });
                    }}
                  />
                  <TokenGroup
                    items={form.fieldValues.coverageAreas}
                    onDismiss={({ detail }) => {
                      setForm({
                        type: "dismissToken",
                        payload: {
                          field: "coverageAreas",
                          value: detail.itemIndex
                        }
                      });
                    }}
                  />
                </FormField>
              </div>
            </ColumnLayout>
          </FormSection>

          <FormSection
            id="partner-specialty-panel"
            header={<h2>Specialty Domains</h2>}
            description={
              <span>
                Specialty domains are areas of expertise for the partner.
                <br />
                These may be areas such as Serverless, Machine Learning,
                Migrations, etc.
                <br />
                Use the <b>ENTER</b> or <b>RETURN</b> key to lock in a value.
                Values are not saved unless they appear in the token group below
                the input box.
              </span>
            }
          >
            <ColumnLayout>
              <div data-awsui-column-layout-root={true}>
                <FormField
                  label={
                    <span>
                      Specialty Domains <i>- optional</i>
                    </span>
                  }
                >
                  <Input
                    id="specialtyDomains"
                    value={form.fieldValues.specialtyDomainsInput}
                    onInput={({ detail }) => {
                      setForm({
                        type: "string",
                        payload: {
                          field: "specialtyDomainsInput",
                          value: detail.value
                        }
                      });
                    }}
                    onChange={({ detail }) => {
                      setForm({
                        type: "addToken",
                        validation: "required",
                        payload: {
                          field: "specialtyDomains",
                          value: detail.value
                        }
                      });
                    }}
                  />
                  <TokenGroup
                    items={form.fieldValues.specialtyDomains}
                    onDismiss={({ detail }) => {
                      setForm({
                        type: "dismissToken",
                        payload: {
                          field: "specialtyDomains",
                          value: detail.itemIndex
                        }
                      });
                    }}
                  />
                </FormField>
              </div>
            </ColumnLayout>
          </FormSection>
        </Form>
      </div>
    );
};

export default EditOrganizationDetailsForm;
