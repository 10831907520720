import React, { useState, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Auth as AmplifyAuth } from "aws-amplify";
import { fetchUserProfile } from "../../../utils";
import { setCurrentUser, setUserIsAuthenticated } from "../../../actions";
import { formReducer } from "../../../reducers";
import { formIsValid } from "../../../utils";

import logo from "../../../img/AWS_APN_PartnerNetwork_ImmersionDay_Dark.png";
import "../../../styles/auth.scss";

import {
  Button,
  ColumnLayout,
  Form,
  FormField,
  FormSection,
  Input
} from "@amzn/awsui-components-react";

const DEFAULT_STATE = {
  fieldValues: {
    email: "",
    password: ""
  },
  fieldStates: {
    email: "",
    password: ""
  },
  requiredFields: ["email", "password"]
};

const LoginForm = ({ history }) => {
  // Check if user is alread logged in and redirect to home
  const currentUser = useSelector(state => state.app.currentUser);
  if (currentUser) history.push("/");

  // Component states
  const [form, setForm] = useReducer(formReducer, DEFAULT_STATE);
  const [formErrorText, setFormErrorText] = useState(null);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const submitForm = async () => {
    setLoading(true);
    let input = { ...form.fieldValues };

    try {
      const user = await AmplifyAuth.signIn(input.email, input.password);
      dispatch(setCurrentUser(await fetchUserProfile(user)));
      dispatch(setUserIsAuthenticated(true));
      history.push("/");
    } catch (e) {
      console.log(JSON.stringify(e, null, 2));
      setFormErrorText(
        <p>
          There was a problem logging in. Check your username and password and
          try again.
          <br />
          <br />
          <a href="/auth/forgot-password">Forgot password? Click here.</a>
        </p>
      );
      setLoading(false);
    }
  };

  return (
    <div id="mainPanel" className="auth-form">
      <Form
        header={
          <img
            src={logo}
            className="auth-logo"
            alt="APN Immersion Day Login Form"
          />
        }
        errorText={formErrorText}
        actions={
          <div>
            <Button
              variant="primary"
              loading={loading}
              onClick={() => {
                if (formIsValid(form)) {
                  setFormErrorText("");
                  submitForm();
                } else {
                  setFormErrorText(
                    "Please fill out all of the required form fields."
                  );
                }
              }}
            >
              Login
            </Button>
          </div>
        }
      >
        <FormSection id="login-panel" header="Login">
          <ColumnLayout>
            <div data-awsui-column-layout-root={true}>
              <FormField
                label="Email Address"
                stretch={true}
                errorText={
                  form.fieldStates.email === "" || form.fieldStates.email
                    ? ""
                    : "Must be a valid email address."
                }
              >
                <Input
                  id="email"
                  value={form.fieldValues.email}
                  onInput={({ detail }) => {
                    setForm({
                      type: "email",
                      required: true,
                      payload: {
                        field: "email",
                        value: detail.value
                      }
                    });
                  }}
                />
              </FormField>
              <FormField
                label="Password"
                stretch={true}
                errorText={
                  form.fieldStates.password === "" || form.fieldStates.password
                    ? ""
                    : "This field is required."
                }
              >
                <Input
                  id="password"
                  type="password"
                  value={form.fieldValues.password}
                  invalid={
                    !(form.fieldStates.password === ""
                      ? true
                      : form.fieldStates.password)
                  }
                  onInput={({ detail }) => {
                    setForm({
                      type: "password",
                      required: true,
                      payload: {
                        field: "password",
                        value: detail.value
                      }
                    });
                  }}
                />
              </FormField>
            </div>
          </ColumnLayout>
        </FormSection>
      </Form>
    </div>
  );
};

export default LoginForm;
