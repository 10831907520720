import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { updateBreadCrumbs } from "../../actions";

import FeedbackForm from "./components/FeedbackForm";

const FeedbackPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      updateBreadCrumbs([
        {
          text: "APN Immersion Day",
          href: "/"
        },
        {
          text: "Leave Feedback",
          href: "/feedback"
        }
      ])
    );
  }, [dispatch]);

  return <FeedbackForm />;
};

export default FeedbackPage;
