import React from "react";

import { Table } from "@amzn/awsui-components-react";

import { RESOURCE_COLUMN_DEFINITIONS } from "./ResourceTableConfig";

const ResourceTable = ({ header, items }) => {
  return (
    <div className="awsui-util-mb-l">
      <Table
        columnDefinitions={RESOURCE_COLUMN_DEFINITIONS}
        items={items}
        empty={
          <div className="awsui-util-t-c">
            <div className="awsui-util-pt-s awsui-util-mb-xs">
              <b>{`No ${header}`}</b>
            </div>
            <p className="awsui-util-mb-s">{`No ${header} to display.`}</p>
          </div>
        }
      />
    </div>
  );
};

export default ResourceTable;
