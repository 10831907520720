/* eslint-disable indent */
/* eslint-disable no-throw-literal */

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { API, graphqlOperation } from "aws-amplify";
import { getEvent } from "../../../graphql/queries";
import { addFlash } from "../../../actions";

import {
  Button,
  ColumnLayout,
  Icon,
  Spinner
} from "@amzn/awsui-components-react";

import { formatDate, formatListItems, objIsEmpty } from "../../../utils";

import CsatSurveyContainer from "./CsatSurveyContainer";
import CsatResultsTable from "./CsatResultsTable";

const EventDetail = ({ eventId, history }) => {
  const currentUser = useSelector(state => state.app.currentUser);

  const [loading, setLoading] = useState(false);
  const [eventDetail, setEventDetail] = useState(false);

  const dispatch = useDispatch();

  // Get data on page load
  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line

  // Fetch data from GraphQL API
  const fetchData = async () => {
    setLoading(true);
    try {
      const result = await API.graphql(
        graphqlOperation(getEvent, { id: eventId })
      );
      const detail = result.data.getEvent;

      /**
       * THROW ERROR CONDITIONS
       */

      if (!detail) {
        throw {
          msg: "Event not found.",
          action: history.push("/events/calendar")
        };
      }

      /**
       * CONTINUE IF NO ERRORS
       */

      setEventDetail(detail);
    } catch (err) {
      dispatch(
        addFlash({
          type: "error",
          content: err.msg
        })
      );
      if (err.action) err.action();
    }
    setLoading(false);
  };

  return (
    <>
      <div className="awsui-util-container">
        <div className="awsui-util-container-header">
          <div className="awsui-util-action-stripe">
            <div className="awsui-util-action-stripe-title">
              <h2>Event Details</h2>
            </div>
            {!loading &&
              eventDetail.status === "Scheduled" &&
              currentUser.id === eventDetail.userId && (
                // Only display the edit button if the event status is "Scheudled"
                // and the current user is the owner of this event
                <div className="awsui-util-action-stripe-group">
                  <Button
                    icon="edit"
                    variant="primary"
                    onClick={() => history.push(`/events/${eventId}/edit`)}
                  >
                    Edit
                  </Button>
                </div>
              )}
          </div>
        </div>
        {loading && (
          <div>
            <span className="awsui-util-status-inactive">
              <Spinner /> Loading
            </span>
          </div>
        )}
        {!loading && !objIsEmpty(eventDetail) && (
          <ColumnLayout columns={3} variant="text-grid">
            <div data-awsui-column-layout-root="true">
              <div>
                <div className="awsui-util-spacing-v-s">
                  <div>
                    <div className="awsui-util-label">Partner Name</div>
                    <div>{eventDetail.partner.name}</div>
                  </div>
                  <div>
                    <div className="awsui-util-label">Event Owner</div>
                    <div>{`${eventDetail.user.firstName} ${eventDetail.user.lastName}`}</div>
                    <div>
                      <a href={`mailto:${eventDetail.user.email}`}>
                        {eventDetail.user.email}
                      </a>
                    </div>
                  </div>
                  <div>
                    <div className="awsui-util-label">Topic</div>
                    <div>{eventDetail.topic}</div>
                  </div>
                </div>
              </div>
              <div>
                <div className="awsui-util-spacing-v-s">
                  <div>
                    <div className="awsui-util-label">Status</div>
                    {eventDetail.status === "Complete" ? (
                      <span className="awsui-util-status-positive">
                        <Icon name="status-positive" /> Complete
                      </span>
                    ) : (
                      <div />
                    )}
                    {eventDetail.status === "Scheduled" ? (
                      <span className="awsui-util-status-info">
                        <Icon name="status-pending" /> Scheduled
                      </span>
                    ) : (
                      <div />
                    )}
                    {eventDetail.status === "Canceled" ? (
                      <span className="awsui-util-status-negative">
                        <Icon name="status-warning" /> Canceled
                      </span>
                    ) : (
                      <div />
                    )}
                  </div>
                  <div>
                    <div className="awsui-util-label">Date</div>
                    <div>{formatDate(eventDetail.date)}</div>
                  </div>
                  <div>
                    <div className="awsui-util-label">Geo</div>
                    <div>{eventDetail.geo}</div>
                  </div>
                  <div>
                    <div className="awsui-util-label">Location</div>
                    <div>{eventDetail.location}</div>
                  </div>
                </div>
              </div>
              <div>
                <div className="awsui-util-spacing-v-s">
                  <div>
                    <div className="awsui-util-label">Event Type</div>
                    <div>
                      {eventDetail.multiCustomer
                        ? "Multi-Customer"
                        : "Single-Customer"}
                    </div>
                  </div>
                  <div>
                    <div className="awsui-util-label">Registration Count</div>
                    <div>
                      {eventDetail.registrationCount
                        ? eventDetail.registrationCount
                        : "-"}
                    </div>
                  </div>
                  <div>
                    <div className="awsui-util-label">Attendee Count</div>
                    <div>
                      {eventDetail.attendeeCount
                        ? eventDetail.attendeeCount
                        : "-"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ColumnLayout>
        )}
      </div>

      <div className="awsui-util-container">
        <div className="awsui-util-container-header">
          <div className="awsui-util-action-stripe">
            <div className="awsui-util-action-stripe-title">
              <h2>{eventDetail.multiCustomer ? "Customers" : "Customer"}</h2>
            </div>
          </div>
        </div>
        {loading && (
          <div>
            <span className="awsui-util-status-inactive">
              <Spinner /> Loading
            </span>
          </div>
        )}
        {!loading && !objIsEmpty(eventDetail) && (
          <ColumnLayout columns={3}>
            <div data-awsui-column-layout-root="true">
              {eventDetail.customers.map(formatListItems)}
            </div>
          </ColumnLayout>
        )}
      </div>

      {!loading &&
        !objIsEmpty(eventDetail) &&
        eventDetail.status !== "Complete" && (
          <CsatSurveyContainer
            loading={loading}
            csatSurveyUrl={
              !objIsEmpty(eventDetail.csat) ? eventDetail.csat.surveyUrl : false
            }
          />
        )}

      {!loading &&
        !objIsEmpty(eventDetail) &&
        eventDetail.status === "Complete" && (
          <CsatResultsTable
            loading={loading}
            csatFeedback={
              eventDetail.csat.feedback ? eventDetail.csat.feedback : []
            }
          />
        )}
    </>
  );
};

export default EventDetail;
