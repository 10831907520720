import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { updateBreadCrumbs } from "../../actions";

import EditUserProfileForm from "./components/EditUserProfileForm";

const UserProfilePage = props => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      updateBreadCrumbs([
        {
          text: "APN Immersion Day",
          href: "/"
        },
        {
          text: "User Profile",
          href: "/user-profile"
        }
      ])
    );
  }, [dispatch]);

  return <EditUserProfileForm history={props.history} />;
};

export default UserProfilePage;
