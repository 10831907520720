import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { updateBreadCrumbs } from "../../actions";

import EditEventDetailForm from "./components/EditEventDetailForm";

import "../../styles/eventStyles.scss";

const EventDetailPage = props => {
  const { history, match } = props;
  const { eventId } = match.params;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      updateBreadCrumbs([
        {
          text: "APN Immersion Day",
          href: "/"
        },
        {
          text: "Event Details",
          href: `/events/${eventId}`
        },
        {
          text: "Edit",
          href: null
        }
      ])
    );
  }, [dispatch, eventId]);

  return <EditEventDetailForm eventId={eventId} history={history} />;
};

export default EventDetailPage;
