import React, { useState, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";

import moment from "moment";
import { API, graphqlOperation } from "aws-amplify";
import { createEvent, createCsatSurvey } from "../../../graphql/mutations";
import { addFlash } from "../../../actions";
import { formReducer } from "../../../reducers";
import {
  immersionDayTopicOptions,
  geoOptions,
  formIsValid
} from "../../../utils";

import {
  Button,
  ColumnLayout,
  DatePicker,
  Form,
  FormField,
  FormSection,
  Input,
  RadioGroup,
  Select,
  Toggle
} from "@amzn/awsui-components-react";

const DEFAULT_STATE = {
  fieldValues: {
    multiCustomer: false,
    customers: "",
    date: "",
    deliveryMethod: "inperson",
    location: "",
    geo: "",
    topic: ""
  },
  fieldStates: {
    customers: "",
    date: "",
    deliveryMethod: true,
    location: "",
    geo: "",
    topic: ""
  },
  requiredFields: [
    "customers",
    "date",
    "deliveryMethod",
    "location",
    "geo",
    "topic"
  ]
};

const now = new Date();
const y = now.getFullYear();
const m = now.getMonth();
const d = now.getDate();
const yesterday = new Date(y, m, d - 1);

const ScheduleAnEventForm = ({ history }) => {
  const currentUser = useSelector(state => state.app.currentUser);

  // Component states
  const [form, setForm] = useReducer(formReducer, DEFAULT_STATE);
  const [formErrorText, setFormErrorText] = useState(null);
  const [saving, setSaving] = useState(false);

  const dispatch = useDispatch();

  const submitForm = async () => {
    setSaving(true);
    let input = { ...form.fieldValues };

    // Handle customers field
    let customers = [];
    if (!input.multiCustomer) customers.push(input.customers);
    input.customers = customers;

    // Handle select fields
    input.geo = input.geo.label;
    input.topic = input.topic.label;

    // Format the date field
    input.date = moment(input.date);

    // Add additional fields
    input.partnerId = currentUser.partner.id;
    input.userId = currentUser.id;
    input.status = "Scheduled";

    // console.log(`input: ${JSON.stringify(input, null, 2)}`);

    try {
      // Save the event to DynamoDB
      const createEventResult = await API.graphql(
        graphqlOperation(createEvent, { input })
      );

      const eventInfo = {
        eventId: createEventResult.data.createEvent.id,
        partnerId: createEventResult.data.createEvent.partner.id,
        partnerName: createEventResult.data.createEvent.partner.name,
        deliveryMethod: createEventResult.data.createEvent.deliveryMethod,
        surveyType: createEventResult.data.createEvent.deliveryMethod,
        qualtricsApiKeyParam: "csat-qualtrics-api-key-epperale",
        location: createEventResult.data.createEvent.location,
        topic: createEventResult.data.createEvent.topic,
        date: createEventResult.data.createEvent.date
      };

      await API.graphql(
        graphqlOperation(createCsatSurvey, { input: eventInfo })
      );

      history.push("/events/scheduled");
    } catch (e) {
      dispatch(
        addFlash({
          type: "error",
          header: "An error occurred",
          content: "Please try again in a little while."
        })
      );
    }

    setSaving(false);
  };

  return (
    <div id="mainPanel">
      <Form
        header={
          <span>
            <h1>Schedule an Event</h1>
          </span>
        }
        errorText={formErrorText}
        actions={
          <div>
            <Button
              variant="primary"
              loading={saving}
              onClick={() => {
                if (formIsValid(form)) {
                  setFormErrorText("");
                  submitForm();
                } else {
                  setFormErrorText(
                    "Please fill out all of the required form fields."
                  );
                }
              }}
            >
              Schedule
            </Button>
          </div>
        }
      >
        <FormSection
          id="schedule-event-form-panel"
          header={<h2>Event Information</h2>}
        >
          <ColumnLayout>
            <div data-awsui-column-layout-root={true}>
              <FormField>
                <Toggle
                  id="multiCustomer"
                  value={form.fieldValues.multiCustomer}
                  onChange={({ detail }) => {
                    const toggleState = detail.checked;
                    if (toggleState) {
                      setForm({
                        type: "toggle",
                        payload: {
                          field: "multiCustomer",
                          value: detail
                        }
                      });
                      setForm({
                        type: "override",
                        payload: {
                          field: "customers",
                          value: "",
                          state: true
                        }
                      });
                    } else {
                      setForm({
                        type: "toggle",
                        payload: {
                          field: "multiCustomer",
                          value: detail
                        }
                      });
                      setForm({
                        type: "override",
                        payload: {
                          field: "customers",
                          value: "",
                          state: ""
                        }
                      });
                    }
                  }}
                >
                  Multi-Customer?
                </Toggle>
              </FormField>
              <FormField
                label={
                  form.fieldValues.multiCustomer ? "Customers" : "Customer"
                }
                errorText={
                  form.fieldStates.customers === "" ||
                  form.fieldStates.customers
                    ? ""
                    : "This field is required."
                }
              >
                <Input
                  id="customers"
                  value={form.fieldValues.customers}
                  placeholder={
                    form.fieldValues.multiCustomer
                      ? "Add multiple customers on the Edit Event page once they register for the event."
                      : ""
                  }
                  disabled={form.fieldValues.multiCustomer}
                  onInput={({ detail }) => {
                    setForm({
                      type: "string",
                      required: true,
                      payload: {
                        field: "customers",
                        value: detail.value
                      }
                    });
                  }}
                />
              </FormField>
              <FormField
                label="Event Date"
                errorText={
                  form.fieldStates.date === "" || form.fieldStates.date
                    ? ""
                    : "The event date is required and must not be in the past."
                }
              >
                <DatePicker
                  id="date"
                  placeholder="YYYY/MM/DD"
                  todayLabel="Today"
                  nextMonthLabel="Next month"
                  previousMonthLabel="Previous month"
                  isDateEnabled={date => date > yesterday}
                  value={form.fieldValues.date}
                  onInput={({ detail }) => {
                    setForm({
                      type: "date",
                      required: true,
                      payload: {
                        field: "date",
                        value: detail.value,
                        isAfter: yesterday
                      }
                    });
                  }}
                  onChange={({ detail }) => {
                    setForm({
                      type: "date",
                      required: true,
                      payload: {
                        field: "date",
                        value: detail.value,
                        isAfter: yesterday
                      }
                    });
                  }}
                />
              </FormField>
              <FormField
                label="Delivery Method"
                description="This will determine the questions on the CSAT survey."
                errorText={
                  form.fieldStates.deliveryMethod === "" ||
                  form.fieldStates.deliveryMethod
                    ? ""
                    : "This field is required."
                }
              >
                <RadioGroup
                  id="deliveryMethod"
                  value={form.fieldValues.deliveryMethod}
                  items={[
                    {
                      value: "inperson",
                      label: "In-person"
                    },
                    {
                      value: "virtual",
                      label: "Virtual"
                    }
                  ]}
                  onChange={({ detail }) => {
                    setForm({
                      type: "string",
                      required: true,
                      payload: {
                        field: "deliveryMethod",
                        value: detail.value
                      }
                    });
                  }}
                />
              </FormField>
              <FormField
                label="Event Location"
                errorText={
                  form.fieldStates.location === "" || form.fieldStates.location
                    ? ""
                    : "This field is required."
                }
              >
                <Input
                  id="location"
                  placeholder="City, State, Country"
                  value={form.fieldValues.location}
                  onInput={({ detail }) => {
                    setForm({
                      type: "string",
                      required: true,
                      payload: {
                        field: "location",
                        value: detail.value
                      }
                    });
                  }}
                />
              </FormField>
              <FormField
                label="Event Geo"
                errorText={
                  form.fieldStates.geo === "" || form.fieldStates.geo
                    ? ""
                    : "This field is required."
                }
              >
                <Select
                  id="geo"
                  placeholder="Select the event geo"
                  options={geoOptions}
                  selectedOption={form.fieldValues.geo}
                  onChange={({ detail }) => {
                    setForm({
                      type: "object",
                      required: true,
                      payload: {
                        field: "geo",
                        value: detail.selectedOption
                      }
                    });
                  }}
                />
              </FormField>
              <FormField
                label="Immersion Day Topic"
                errorText={
                  form.fieldStates.topic === "" || form.fieldStates.topic
                    ? ""
                    : "This field is required."
                }
              >
                <Select
                  id="topic"
                  placeholder="Select the event topic"
                  options={immersionDayTopicOptions}
                  selectedOption={form.fieldValues.topic}
                  onChange={({ detail }) => {
                    setForm({
                      type: "object",
                      required: true,
                      payload: {
                        field: "topic",
                        value: detail.selectedOption
                      }
                    });
                  }}
                />
              </FormField>
            </div>
          </ColumnLayout>
        </FormSection>
      </Form>
    </div>
  );
};

export default ScheduleAnEventForm;
