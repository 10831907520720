import React from "react";
import { ColumnLayout, Spinner } from "@amzn/awsui-components-react";

const CsatSurveyContainer = ({ csatSurveyUrl, loading }) => {
  return (
    <div className="awsui-util-container">
      <div className="awsui-util-container-header">
        <div className="awsui-util-action-stripe">
          <div className="awsui-util-action-stripe-title">
            <h2>CSAT Survey</h2>
          </div>
        </div>
      </div>
      {loading && (
        <div>
          <span className="awsui-util-status-inactive">
            <Spinner /> Loading
          </span>
        </div>
      )}
      {!loading && csatSurveyUrl && (
        <ColumnLayout>
          <div data-awsui-column-layout-root="true">
            <div>
              <a href={csatSurveyUrl} target="_blank" rel="noopener noreferrer">
                {csatSurveyUrl}
              </a>
            </div>
          </div>
        </ColumnLayout>
      )}
      {!loading && !csatSurveyUrl && (
        <ColumnLayout>
          <div data-awsui-column-layout-root="true">
            <div>
              The survey is being created. Check back in a moment for the survey
              link.
            </div>
          </div>
        </ColumnLayout>
      )}
    </div>
  );
};

export default CsatSurveyContainer;
