import React, { useEffect, useState, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";

import { API, graphqlOperation, Auth as AmplifyAuth } from "aws-amplify";
import { getUser } from "../../../graphql/queries";
import { updateUser } from "../../../graphql/mutations";
import { formReducer } from "../../../reducers";
import { setCurrentUser, addFlash } from "../../../actions";
import {
  certificationOptions,
  certificationLabelLookup,
  formIsValid,
  fetchUserProfile
} from "../../../utils";

import {
  Button,
  ColumnLayout,
  Form,
  FormField,
  FormSection,
  Input,
  Spinner,
  TokenGroup,
  Multiselect
} from "@amzn/awsui-components-react";

const DEFAULT_STATE = {
  fieldValues: {
    firstName: "",
    lastName: "",
    title: "",
    phoneno: "",
    certificationsInput: "",
    certifications: [],
    coverageAreasInput: "",
    coverageAreas: []
  },
  fieldStates: {
    firstName: "",
    lastName: "",
    title: "",
    phoneno: "",
    certifications: "",
    coverageAreas: ""
  },
  requiredFields: ["firstName", "lastName", "title"]
};

const EditUserProfileForm = ({ history }) => {
  const currentUser = useSelector(state => state.app.currentUser);

  // Component states
  const [form, setForm] = useReducer(formReducer, DEFAULT_STATE);
  const [formErrorText, setFormErrorText] = useState(null);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);

  // Get data on page load
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const result = await API.graphql(
        graphqlOperation(getUser, { id: currentUser.id })
      );

      let userDetail = result.data.getUser;

      const formInitState = {
        fieldValues: {
          firstName: userDetail.firstName,
          lastName: userDetail.lastName,
          title: userDetail.title,
          phoneno: userDetail.phoneno,
          certifications: userDetail.certifications
            ? userDetail.certifications.map(val => ({ id: val, label: certificationLabelLookup(val) })) //eslint-disable-line
            : [],
          coverageAreas: userDetail.coverageAreas
            ? userDetail.coverageAreas.map(val => ({ label: val }))
            : []
        },
        fieldStates: {
          firstName: true,
          lastName: true,
          title: true,
          phoneno: true,
          certifications: true,
          coverageAreas: true
        },
        requiredFields: ["firstName", "lastName", "title"]
      };

      setForm({
        type: "initialize",
        payload: formInitState
      });

      setLoading(false);
    };

    fetchData();
  }, [currentUser]);

  const dispatch = useDispatch();

  const submitForm = async () => {
    setSaving(true);
    let input = { ...form.fieldValues };

    // Inject the user's ID
    input.id = currentUser.id;

    // Handle optional and unwanted fields
    input.phoneno = input.phoneno !== "" ? input.phoneno : null;
    delete input.coverageAreasInput;

    // Handle list fields
    input.certifications = input.certifications.map(cert => cert.id);
    input.coverageAreas = input.coverageAreas.map(area => area.label);

    try {
      // Save the profile to DynamoDB
      await API.graphql(graphqlOperation(updateUser, { input }));

      // Update the user profile in the app state
      const user = await AmplifyAuth.currentAuthenticatedUser();
      dispatch(setCurrentUser(await fetchUserProfile(user)));
      dispatch(
        addFlash({
          type: "success",
          content: "Profile saved."
        })
      );
    } catch (e) {
      dispatch(
        addFlash({
          type: "error",
          header: "An error occurred",
          content: "Please try again in a little while."
        })
      );
    }

    setSaving(false);
  };

  if (loading) {
    return (
      <span>
        <Spinner size="large" />
      </span>
    );
  } else {
    return (
      <div id="mainPanel">
        <Form
          header={
            <span>
              <h1>Update User Profile</h1>
            </span>
          }
          errorText={formErrorText}
          actions={
            <div>
              <Button
                variant="primary"
                loading={saving}
                onClick={() => {
                  if (formIsValid(form)) {
                    setFormErrorText("");
                    submitForm();
                  } else {
                    setFormErrorText(
                      "Please fill out all of the required form fields."
                    );
                  }
                }}
              >
                Save
              </Button>
            </div>
          }
        >
          <FormSection
            id="user-profile-form-panel"
            header={<h2>User Information</h2>}
          >
            <ColumnLayout columns={2}>
              <div data-awsui-column-layout-root={true}>
                <FormField
                  label="First Name"
                  errorText={
                    form.fieldStates.firstName ? "" : "This field is required."
                  }
                >
                  <Input
                    id="firstName"
                    value={form.fieldValues.firstName}
                    onInput={({ detail }) => {
                      setForm({
                        type: "string",
                        required: true,
                        payload: {
                          field: "firstName",
                          value: detail.value
                        }
                      });
                    }}
                  />
                </FormField>
                <FormField
                  label="Last Name"
                  errorText={
                    form.fieldStates.lastName ? "" : "This field is required."
                  }
                >
                  <Input
                    id="lastName"
                    value={form.fieldValues.lastName}
                    onInput={({ detail }) => {
                      setForm({
                        type: "string",
                        required: true,
                        payload: {
                          field: "lastName",
                          value: detail.value
                        }
                      });
                    }}
                  />
                </FormField>
                <FormField
                  label="Business Title"
                  errorText={
                    form.fieldStates.title ? "" : "This field is required."
                  }
                >
                  <Input
                    id="title"
                    value={form.fieldValues.title}
                    onInput={({ detail }) => {
                      setForm({
                        type: "string",
                        required: true,
                        payload: {
                          field: "title",
                          value: detail.value
                        }
                      });
                    }}
                  />
                </FormField>
                <FormField
                  stretch={true}
                  label={
                    <span>
                      Phone Number<i> - optional</i>
                    </span>
                  }
                  errorText={
                    form.fieldStates.phoneno ? "" : "This field is malformed."
                  }
                >
                  <Input
                    id="phoneno"
                    value={form.fieldValues.phoneno}
                    onInput={({ detail }) => {
                      setForm({
                        type: "phone",
                        payload: {
                          field: "phoneno",
                          value: detail.value
                        }
                      });
                    }}
                  />
                </FormField>
              </div>
            </ColumnLayout>
          </FormSection>

          <FormSection
            id="user-certifications-form-panel"
            header={<h2>User Certifications</h2>}
            description={
              <span>
                Select your achieved AWS Certifications
                <br />
                Use the <b>ENTER</b> or <b>RETURN</b> key to lock in a value.
                Values are not saved unless they appear in the token group below
                the input box.
              </span>
            }
          >
            <ColumnLayout>
              <div data-awsui-column-layout-root={true}>
                <FormField>
                  <Multiselect
                    placeholder="Select Certifications"
                    options={certificationOptions}
                    selectedOptions={form.fieldValues.certifications}
                    checkboxes={true}
                    onChange={({ detail }) => {
                      setForm({
                        type: "object",
                        payload: {
                          field: "certifications",
                          value: detail.selectedOptions
                        }
                      });
                    }}
                  />
                </FormField>
              </div>
            </ColumnLayout>
          </FormSection>

          <FormSection
            id="coverage-areas-form-panel"
            header={<h2>Coverage Areas</h2>}
            description={
              <span>
                Specify the coverage areas that you support.
                <br />
                A coverage area should include: City, State, Country
                <br />
                Use the <b>ENTER</b> or <b>RETURN</b> key to lock in a value.
                Values are not saved unless they appear in the token group below
                the input box.
              </span>
            }
          >
            <ColumnLayout>
              <div data-awsui-column-layout-root={true}>
                <FormField>
                  <Input
                    id="coverageAreas"
                    placeholder="City, State, Country"
                    value={form.fieldValues.coverageAreasInput}
                    onInput={({ detail }) => {
                      setForm({
                        type: "string",
                        payload: {
                          field: "coverageAreasInput",
                          value: detail.value
                        }
                      });
                    }}
                    onChange={({ detail }) => {
                      setForm({
                        type: "addToken",
                        payload: {
                          field: "coverageAreas",
                          value: detail.value
                        }
                      });
                    }}
                  />
                  <TokenGroup
                    items={form.fieldValues.coverageAreas}
                    onDismiss={({ detail }) => {
                      setForm({
                        type: "dismissToken",
                        required: true,
                        payload: {
                          field: "coverageAreas",
                          value: detail.itemIndex
                        }
                      });
                    }}
                  />
                </FormField>
              </div>
            </ColumnLayout>
          </FormSection>
        </Form>
      </div>
    );
  }
};

export default EditUserProfileForm;
