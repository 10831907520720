import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import {
  Button,
  Table,
  TableContentSelector,
  TableFiltering,
  TablePageSizeSelector,
  TablePagination,
  TablePreferences,
  TableSorting,
  TableWrapLines
} from "@amzn/awsui-components-react";

import {
  COLUMN_DEFINITIONS,
  CONTENT_SELECTOR_OPTIONS,
  PAGE_SELECTOR_OPTIONS,
  SORTABLE_COLUMNS
} from "./CompleteEventsTableConfig.jsx";

import { API, graphqlOperation } from "aws-amplify";
import { listEventsByUserByDate } from "../../../graphql/apnid-queries";

const CompleteEventsTable = ({ history }) => {
  const currentUser = useSelector(state => state.app.currentUser);
  const userId = currentUser.id;

  const [loading, setLoading] = useState(false);
  const [completeEvents, setCompleteEvents] = useState([]);
  const [pageSize, setPageSize] = useState(30);
  const [filteringText, setFilteringText] = useState("");

  // Get data on page load
  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line

  // Fetch data from GraphQL API
  const fetchData = async () => {
    setLoading(true);
    const filter = { status: { eq: "Complete" } };
    const result = await API.graphql(
      graphqlOperation(listEventsByUserByDate, {
        userId,
        limit: 100000,
        filter
      })
    );
    setCompleteEvents(result.data.eventsByUserByDate.items);
    setLoading(false);
  };

  // Updates the page size in preferences
  const onPaginationChange = ({ detail: { pageSize } }) => {
    setPageSize(pageSize);
  };

  // Updates the filtering text
  const onFilteringChange = ({ detail: { filteringText } }) => {
    setFilteringText(filteringText);
  };

  // Resets the filtering text
  const clearFilter = () => {
    setFilteringText("");
  };

  return (
    <Table
      columnDefinitions={COLUMN_DEFINITIONS(history)}
      items={completeEvents}
      loading={loading}
      loadingText="Loading..."
      header={<CompleteEventsTableHeader refreshFn={fetchData} />}
      empty={
        <div className="awsui-util-t-c">
          <div className="awsui-util-pt-s awsui-util-mb-xs">
            <b>No events</b>
          </div>
          <p className="awsui-util-mb-s">No complete events to display.</p>
        </div>
      }
      noMatch={
        <div className="awsui-util-t-c">
          <div className="awsui-util-pt-xs awsui-util-mb-xs">
            <b>No matches</b>
          </div>
          <p className="awsui-util-mb-s">No results match your query</p>
          <div className="awsui-util-mb-l">
            <Button onClick={clearFilter.bind(this)}>Clear filter</Button>
          </div>
        </div>
      }
    >
      <TableFiltering
        filteringPlaceholder="Search complete events"
        filteringText={filteringText}
        onFilteringChange={onFilteringChange.bind(this)}
      />
      <TablePagination
        onPaginationChange={onPaginationChange.bind(this)}
        pageSize={pageSize}
      />
      <TableSorting
        sortingColumn="date"
        sortingDescending={true}
        sortableColumns={SORTABLE_COLUMNS}
      />
      <TablePreferences
        title="Preferences"
        confirmLabel="Confirm"
        cancelLabel="Cancel"
      >
        <TablePageSizeSelector
          title="Page size"
          options={PAGE_SELECTOR_OPTIONS}
        />
        <TableWrapLines
          label="Wrap lines"
          description="Check to see all the text and wrap the lines"
          value={false}
        />
        <TableContentSelector
          title="Select visible columns"
          options={CONTENT_SELECTOR_OPTIONS}
        />
      </TablePreferences>
    </Table>
  );
};

const CompleteEventsTableHeader = ({ refreshFn }) => {
  return (
    <div className="awsui-util-action-stripe">
      <div className="awsui-util-action-stripe-title">
        <h2>My Complete Events</h2>
      </div>
      <div className="awsui-util-action-stripe-group">
        <Button icon="refresh" onClick={() => refreshFn()} />
      </div>
    </div>
  );
};

export default CompleteEventsTable;
