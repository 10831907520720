import React from "react";
import YAML from "yamljs";
import { Storage, Analytics } from "aws-amplify";
import { Auth as AmplifyAuth, API, graphqlOperation } from "aws-amplify";
import { getUser } from "../graphql/queries";

export const formatListItems = item => {
  return <div key={item}>{item}</div>;
};

export const objIsEmpty = obj => {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
};

/**
 * Ensure email address domain is in a valid format
 * @param {String} emailDomain
 * - Email address to verify
 * @return {Boolean}
 */
export function verifyEmailDomain(emailDomain) {
  var emailRex = /^@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (emailRex.test(emailDomain)) {
    return true;
  }
  return false;
}

export function shortbread() {
  var shortbread = window.AWSCShortbread({
    parent: document.body,
    domain: ".".concat(window.location.hostname),
    onConsentChanged: function (c) { console.log("Cookie written", c) },
    queryGeolocation: function (geolocatedIn) { geolocatedIn("EU") }
  });
  shortbread.checkForCookieConsent();
}

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

export const formatDate = date => {
  const dateStr = new Date(date);
  return `${
    months[dateStr.getMonth()]
  } ${dateStr.getDate()}, ${dateStr.getFullYear()}`;
};

/**
 * Returns the authenticated user's profile information.
 * @param   {AmplifyAuth} currentAuthenticatedUser
 * @return  {Object}
 */
export const fetchUserProfile = async currentAuthenticatedUser => {
  const userSession = await AmplifyAuth.currentSession();
  const userId = currentAuthenticatedUser.username;
  const userProfile = await API.graphql(
    graphqlOperation(getUser, { id: userId })
  );
  let profileData = userProfile.data.getUser;
  profileData.groups = userSession.accessToken.payload["cognito:groups"];
  return profileData;
};

export const certificationOptions = [
  {
    id: "cloud-practitioner",
    label: "AWS Certified Cloud Practitioner"
  },
  {
    id: "associate-sa",
    label: "AWS Certified Solutions Architect - Associate"
  },
  {
    id: "associate-sysops",
    label: "AWS Certified SysOps Administrator - Associate"
  },
  {
    id: "associate-developer",
    label: "AWS Certified Developer - Associate"
  },
  {
    id: "professional-sa",
    label: "AWS Certified Solutions Architect - Professional"
  },
  {
    id: "professional-devops-engineer",
    label: "AWS Certified DevOps Engineer - Professional"
  },
  {
    id: "specialty-adv-networking",
    label: "AWS Certified Advanced Networking - Specialty"
  },
  {
    id: "specialty-big-data",
    label: "AWS Certified Big Data - Specialty"
  },
  {
    id: "specialty-database",
    label: "AWS Certified Database - Specialty"
  },
  {
    id: "specialty-security",
    label: "AWS Certified Security - Specialty"
  },
  {
    id: "specialty-machine-learning",
    label: "AWS Certified Machine Learning - Specialty"
  },
  {
    id: "specialty-alexa-skill-builder",
    label: "AWS Certified Alexa Skill Builder - Specialty"
  }
];

export const certificationLabelLookup = val => {
  const obj = certificationOptions.find(x => x.id === val);
  return obj.label;
};

export const formIsValid = form => {
  let formErrors = 0;

  let key;

  for (key in form.fieldStates) {
    let keyIsRequired = form.requiredFields.indexOf(key) > -1 ? true : false;

    if (
      keyIsRequired &&
      (form.fieldStates[key] === false || form.fieldStates[key] === "")
    )
      formErrors += 1;
    if (form.fieldStates[key] === false) formErrors += 1;
  }

  return formErrors === 0 ? true : false;
};

/**
 * Load a YAML file from S3 and parse it
 * @param {String} source
 */
export const loadYamlFromS3 = source => {
  return new Promise((accept, reject) => {
    Storage.get(source).then(contentUrl => {
      fetch(contentUrl)
        .then(response => response.text())
        .then(text => accept(YAML.parse(text)))
        .catch(err => reject(err));
    });
  });
};

/**
 * Load a Markdown file from S3
 * @param {String} source
 */
export const loadMarkdownFromS3 = source => {
  return new Promise((accept, reject) => {
    Storage.get(source).then(contentUrl => {
      fetch(contentUrl)
        .then(response => accept(response.text()))
        .catch(err => reject(err));
    });
  });
};

/**
 * Handle clicks download buttons
 * @param {*} event
 * @param {String} key
 *        S3 object key to download
 */
export const handleDownload = (event, key) => {
  Analytics.record({
    name: "contentDownload",
    attributes: { file: key }
  });
  event.preventDefault();
  Storage.get(key)
    .then(result => {
      window.open(result.toString(), "_blank");
    })
    .catch(err => console.log(err));
};

export const deliveryMethodMap = type => {
  if (!type) return "";
  else return type === "virtual" ? "Virtual" : "In-person";
};

export const immersionDayTopicOptions = [
    {
      "label": "General Immersion Day (Japanese)",
      "id": "general-jp"
    },
    {
      "label": "AWS Alien Attack A Serverless Streaming",
      "id": "AWS-Alien-Attack-A-Serverless-Streaming"
    },
    {
      "label": "AWS AppSync",
      "id": "AWS-AppSync"
    },
    {
      "label": "AWS Cloud Development Kit CDK",
      "id": "AWS-Cloud-Development-Kit-CDK"
    },
    {
      "label": "AWS CloudFormation",
      "id": "AWS-CloudFormation"
    },
    {
      "label": "AWS Data Exchange",
      "id": "AWS-Data-Exchange"
    },
    {
      "label": "AWS DeepRacer",
      "id": "AWS-DeepRacer"
    },
    {
      "label": "AWS Glue DataBrew",
      "id": "AWS-Glue-DataBrew"
    },
    {
      "label": "AWS Local Zones",
      "id": "AWS-Local-Zones"
    },
    {
      "label": "AWS Mainframe Modernization",
      "id": "AWS-Mainframe-Modernization"
    },
    {
      "label": "AWS Networking",
      "id": "AWS-Networking"
    },
    {
      "label": "AWS Outposts Racks and Servers",
      "id": "AWS-Outposts-Racks-and-Servers"
    },
    {
      "label": "AWS Panorama",
      "id": "AWS-Panorama"
    },
    {
      "label": "AWS Service Catalog",
      "id": "AWS-Service-Catalog"
    },
    {
      "label": "AWS WellArchitected",
      "id": "AWS-WellArchitected"
    },
    {
      "label": "AWS for Games",
      "id": "AWS-for-Games"
    },
    {
      "label": "AWS for Microsoft Workloads",
      "id": "AWS-for-Microsoft-Workloads"
    },
    {
      "label": "Amazon Aurora",
      "id": "Amazon-Aurora"
    },
    {
      "label": "Amazon CodeWhisperer",
      "id": "Amazon-CodeWhisperer"
    },
    {
      "label": "Amazon DocumentDB",
      "id": "Amazon-DocumentDB"
    },
    {
      "label": "Amazon DynamoDB",
      "id": "Amazon-DynamoDB"
    },
    {
      "label": "Amazon EKS Security",
      "id": "Amazon-EKS-Security"
    },
    {
      "label": "Amazon EMR",
      "id": "Amazon-EMR"
    },
    {
      "label": "Amazon Forecast",
      "id": "Amazon-Forecast"
    },
    {
      "label": "Amazon Keyspaces",
      "id": "Amazon-Keyspaces"
    },
    {
      "label": "Amazon MSK",
      "id": "Amazon-MSK"
    },
    {
      "label": "Amazon MWAA",
      "id": "Amazon-MWAA"
    },
    {
      "label": "Amazon Machine Learning Low Code",
      "id": "Amazon-Machine-Learning-Low-Code"
    },
    {
      "label": "Amazon MemoryDB for Redis",
      "id": "Amazon-MemoryDB-for-Redis"
    },
    {
      "label": "Amazon Neptune",
      "id": "Amazon-Neptune"
    },
    {
      "label": "Amazon OpenSearch Service",
      "id": "Amazon-OpenSearch-Service"
    },
    {
      "label": "Amazon Personalize for Media",
      "id": "Amazon-Personalize-for-Media"
    },
    {
      "label": "Amazon Personalize for Retail",
      "id": "Amazon-Personalize-for-Retail"
    },
    {
      "label": "Amazon RDS Custom for Oracle",
      "id": "Amazon-RDS-Custom-for-Oracle"
    },
    {
      "label": "Amazon RDS Custom for SQL Server",
      "id": "Amazon-RDS-Custom-for-SQL-Server"
    },
    {
      "label": "Amazon RDS for MariaDB",
      "id": "Amazon-RDS-for-MariaDB"
    },
    {
      "label": "Amazon RDS for Oracle",
      "id": "Amazon-RDS-for-Oracle"
    },
    {
      "label": "Amazon RDS for PostgreSQL",
      "id": "Amazon-RDS-for-PostgreSQL"
    },
    {
      "label": "Amazon RDS for SQL Server",
      "id": "Amazon-RDS-for-SQL-Server"
    },
    {
      "label": "Amazon SageMaker",
      "id": "Amazon-SageMaker"
    },
    {
      "label": "Amazon SageMaker Canvas",
      "id": "Amazon-SageMaker-Canvas"
    },
    {
      "label": "Amazon SageMaker Data Wrangler",
      "id": "Amazon-SageMaker-Data-Wrangler"
    },
    {
      "label": "Amazon SageMaker GroundTruth",
      "id": "Amazon-SageMaker-GroundTruth"
    },
    {
      "label": "Amazon Timestream",
      "id": "Amazon-Timestream"
    },
    {
      "label": "Amazon Transcribe",
      "id": "Amazon-Transcribe"
    },
    {
      "label": "Amazon Verified Permissions",
      "id": "Amazon-Verified-Permissions"
    },
    {
      "label": "Amplify",
      "id": "Amplify"
    },
    {
      "label": "Analytics AIML for Games",
      "id": "Analytics-AIML-for-Games"
    },
    {
      "label": "Application Modernization",
      "id": "Application-Modernization"
    },
    {
      "label": "Athena",
      "id": "Athena"
    },
    {
      "label": "Automotive",
      "id": "Automotive"
    },
    {
      "label": "Autonomous Driving Data Framework ADDF",
      "id": "Autonomous-Driving-Data-Framework-ADDF"
    },
    {
      "label": "Babelfish",
      "id": "Babelfish"
    },
    {
      "label": "Blockchain",
      "id": "Blockchain"
    },
    {
      "label": "Bring Your Own Data BYOD",
      "id": "Bring-Your-Own-Data-BYOD"
    },
    {
      "label": "Build a Data Mesh on AWS",
      "id": "Build-a-Data-Mesh-on-AWS"
    },
    {
      "label": "Building Eventdriven Architectures on AWS",
      "id": "Building-Eventdriven-Architectures-on-AWS"
    },
    {
      "label": "Centralized Operations",
      "id": "Centralized-Operations"
    },
    {
      "label": "Chaos Engineering",
      "id": "Chaos-Engineering"
    },
    {
      "label": "Cloud Contact Center",
      "id": "Cloud-Contact-Center"
    },
    {
      "label": "Cloud Environment Guide CEG",
      "id": "Cloud-Environment-Guide-CEG"
    },
    {
      "label": "Communication Developer Services CDS",
      "id": "Communication-Developer-Services-CDS"
    },
    {
      "label": "Compliance",
      "id": "Compliance"
    },
    {
      "label": "Comprehend",
      "id": "Comprehend"
    },
    {
      "label": "Containers",
      "id": "Containers"
    },
    {
      "label": "Content Moderation",
      "id": "Content-Moderation"
    },
    {
      "label": "Control Tower",
      "id": "Control-Tower"
    },
    {
      "label": "Cost Optimization Workshop",
      "id": "Cost-Optimization-Workshop"
    },
    {
      "label": "Data Engineering",
      "id": "Data-Engineering"
    },
    {
      "label": "Data Governance on AWS",
      "id": "Data-Governance-on-AWS"
    },
    {
      "label": "Data Strategy RealTime Performance Optimization",
      "id": "Data-Strategy-RealTime-Performance-Optimization"
    },
    {
      "label": "Data Strategy Solving Customer Churn",
      "id": "Data-Strategy-Solving-Customer-Churn"
    },
    {
      "label": "DataEDU Higher Education Data Lake",
      "id": "DataEDU-Higher-Education-Data-Lake"
    },
    {
      "label": "Database Migration DMS",
      "id": "Database-Migration-DMS"
    },
    {
      "label": "Decoupled Microservices",
      "id": "Decoupled-Microservices"
    },
    {
      "label": "DevOps",
      "id": "DevOps"
    },
    {
      "label": "EC2 Spot",
      "id": "EC2-Spot"
    },
    {
      "label": "EKS",
      "id": "EKS"
    },
    {
      "label": "EMR on EKS",
      "id": "EMR-on-EKS"
    },
    {
      "label": "Edge Services",
      "id": "Edge-Services"
    },
    {
      "label": "ElastiCache",
      "id": "ElastiCache"
    },
    {
      "label": "End User Computing",
      "id": "End-User-Computing"
    },
    {
      "label": "EndofSupport Migration Program EMP",
      "id": "EndofSupport-Migration-Program-EMP"
    },
    {
      "label": "FSI Risk Compliance",
      "id": "FSI-Risk-Compliance"
    },
    {
      "label": "Fraud Detection with Kafka Flink and DynamoDB",
      "id": "Fraud-Detection-with-Kafka-Flink-and-DynamoDB"
    },
    {
      "label": "General",
      "id": "General"
    },
    {
      "label": "Global Healthcare and Life Sciences HCLS",
      "id": "Global-Healthcare-and-Life-Sciences-HCLS"
    },
    {
      "label": "Glue",
      "id": "Glue"
    },
    {
      "label": "Healthcare and Life Science HCLS AIML",
      "id": "Healthcare-and-Life-Science-HCLS-AIML"
    },
    {
      "label": "HiTech Electronics Semiconductor",
      "id": "HiTech-Electronics-Semiconductor"
    },
    {
      "label": "IAM",
      "id": "IAM"
    },
    {
      "label": "Intelligent Document Processing IDP",
      "id": "Intelligent-Document-Processing-IDP"
    },
    {
      "label": "Inventory Management Forecasting Solution",
      "id": "Inventory-Management-Forecasting-Solution"
    },
    {
      "label": "IoT",
      "id": "IoT"
    },
    {
      "label": "Java on AWS",
      "id": "Java-on-AWS"
    },
    {
      "label": "Kendra",
      "id": "Kendra"
    },
    {
      "label": "Kinesis Data Streams",
      "id": "Kinesis-Data-Streams"
    },
    {
      "label": "Lake Formation",
      "id": "Lake-Formation"
    },
    {
      "label": "Lex",
      "id": "Lex"
    },
    {
      "label": "Machine Learning",
      "id": "Machine-Learning"
    },
    {
      "label": "Migration",
      "id": "Migration"
    },
    {
      "label": "Modern Data Architecture",
      "id": "Modern-Data-Architecture"
    },
    {
      "label": "NET Modernization and Development",
      "id": "NET-Modernization-and-Development"
    },
    {
      "label": "Network Analysis Tools",
      "id": "Network-Analysis-Tools"
    },
    {
      "label": "Observability",
      "id": "Observability"
    },
    {
      "label": "Public Sector Relational Database Modernization with Amazon Aurora",
      "id": "Public-Sector-Relational-Database-Modernization-with-Amazon-Aurora"
    },
    {
      "label": "PurposeBuilt Databases",
      "id": "PurposeBuilt-Databases"
    },
    {
      "label": "QLDB",
      "id": "QLDB"
    },
    {
      "label": "QuickSight",
      "id": "QuickSight"
    },
    {
      "label": "Redshift",
      "id": "Redshift"
    },
    {
      "label": "Rekognition",
      "id": "Rekognition"
    },
    {
      "label": "Research Computing",
      "id": "Research-Computing"
    },
    {
      "label": "Resilience",
      "id": "Resilience"
    },
    {
      "label": "RetailCPG",
      "id": "RetailCPG"
    },
    {
      "label": "S3",
      "id": "S3"
    },
    {
      "label": "SAP on AWS",
      "id": "SAP-on-AWS"
    },
    {
      "label": "SAP on AWS Beyond ERP",
      "id": "SAP-on-AWS-Beyond-ERP"
    },
    {
      "label": "SaaS",
      "id": "SaaS"
    },
    {
      "label": "Security Analytics",
      "id": "Security-Analytics"
    },
    {
      "label": "Security and Compliance",
      "id": "Security-and-Compliance"
    },
    {
      "label": "Serverless",
      "id": "Serverless"
    },
    {
      "label": "Serverless Data Lake",
      "id": "Serverless-Data-Lake"
    },
    {
      "label": "Serverless Observability",
      "id": "Serverless-Observability"
    },
    {
      "label": "Smart Factory",
      "id": "Smart-Factory"
    },
    {
      "label": "Snow Edge Compute",
      "id": "Snow-Edge-Compute"
    },
    {
      "label": "Sports",
      "id": "Sports"
    },
    {
      "label": "Storage",
      "id": "Storage"
    },
    {
      "label": "Transactional Mutable Data Lakes",
      "id": "Transactional-Mutable-Data-Lakes"
    },
    {
      "label": "Using SageMaker with EMR and Glue to train and deploy ML models",
      "id": "Using-SageMaker-with-EMR-and-Glue-to-train-and-deploy-ML-models"
    },
    {
      "label": "VMware Cloud on AWS",
      "id": "VMware-Cloud-on-AWS"
    }
];

export const geoOptions = [
  { id: "1", label: "NA" },
  { id: "2", label: "APAC" },
  { id: "3", label: "China" },
  { id: "4", label: "EMEA" },
  { id: "5", label: "Japan" },
  { id: "6", label: "LATAM" }
];
