/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { updateBreadCrumbs } from "../../actions";
import { loadMarkdownFromS3 } from "../../utils";

import { Spinner } from "@amzn/awsui-components-react";
import Markdown from "react-markdown";

const rootPath = "home/resources/faq";
const contentPath = "markdown/faq.md";

const FaqPage = () => {
  const dispatch = useDispatch();

  const [pageConfig, setPageConfig] = useState(false);
  const [loading, setLoading] = useState(false);

  // Breadcrumbs
  useEffect(() => {
    dispatch(
      updateBreadCrumbs([
        {
          text: "APN Immersion Day",
          href: "/"
        },
        {
          text: "FAQ",
          href: "/resources/faq"
        }
      ])
    );
  }, [dispatch]);

  // Config loader
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      let result = await loadMarkdownFromS3(
        `${rootPath}/${contentPath}`
      );
      
      setPageConfig(result);
      setLoading(false);
    };
    fetchData();
  }, []);

  if (loading) {
    return (
      <span>
        <Spinner size="large" />
      </span>
    );
  } else {
    return (
      <div className="awsui-util-container">
        <div className="awsui-util-container-header">
          <h2>Frequently Asked Questions</h2>
        </div>
        <div><Markdown>{pageConfig}</Markdown></div>
      </div>
    )
  }
};

export default FaqPage;
