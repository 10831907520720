import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { updateBreadCrumbs } from "../../actions";

import EventDetail from "./components/EventDetail";

import "../../styles/eventStyles.scss";

const EventDetailPage = props => {
  const { history, match } = props;
  const { eventId } = match.params;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      updateBreadCrumbs([
        {
          text: "APN Immersion Day",
          href: "/"
        },
        {
          text: "Event Detail",
          href: `/events/${eventId}`
        }
      ])
    );
  }, [dispatch, eventId]);

  return (
    <>
      <EventDetail eventId={eventId} history={history} />
    </>
  );
};

export default EventDetailPage;
