import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { updateBreadCrumbs } from "../../actions";

import ScheduleAnEventForm from "./components/ScheduleAnEventForm";

const ScheduleAnEventPage = props => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      updateBreadCrumbs([
        {
          text: "APN Immersion Day",
          href: "/"
        },
        {
          text: "Schedule an Event",
          href: "/events/schedule"
        }
      ])
    );
  }, [dispatch]);

  return <ScheduleAnEventForm history={props.history} />;
};

export default ScheduleAnEventPage;
