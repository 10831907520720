import React, { useState, useEffect } from "react";

// react plugin for creating charts
import ChartistGraph from "react-chartist";

import { ColumnLayout, Spinner } from "@amzn/awsui-components-react";

import { objIsEmpty } from "../../../utils";

// data components
import { API, graphqlOperation } from "aws-amplify";
import { dashboardQuery } from "../../../graphql/queries";

import "./_my-chartist-settings.scss";
import "chartist/dist/scss/chartist.scss";

const HomeDashboard = () => {
  const [loading, setLoading] = useState(false);
  const [dashboardData, setDashboardData] = useState(false);

  // Get data on page load
  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line

  // Fetch data from GraphQL API
  const fetchData = async () => {
    setLoading(true);
    const result = await API.graphql(graphqlOperation(dashboardQuery));
    const parsedResult = JSON.parse(result.data.dashboardQuery);
    setDashboardData(parsedResult);
    setLoading(false);
  };

  const dataPoints = [
    {
      title: "Immersion Days Delivered",
      data: dashboardData.totalDelivered
    },
    {
      title: "Practitioners Trained",
      data: dashboardData.totalPractitionersTrained
    },
    {
      title: "Average CSAT Score",
      data: dashboardData.averageCsat ? dashboardData.averageCsat : "-"
    }
  ];

  return (
    <>
      <div className="awsui-util-container">
        <div className="awsui-util-container-header">
          <div className="awsui-util-action-stripe">
            <div className="awsui-util-action-stripe-title">
              <h2>APN Immersion Day Program Metrics</h2>
            </div>
          </div>
        </div>
        {loading && (
          <div>
            <span className="awsui-util-status-inactive">
              <Spinner /> Loading
            </span>
          </div>
        )}
        {!loading && !objIsEmpty(dashboardData) && (
          <ColumnLayout columns={3} variant="text-grid">
            <div data-awsui-column-layout-root="true">
              {dataPoints.map((record, key) => (
                <div key={key}>
                  <div className="awsui-util-spacing-v-s">
                    <div>
                      <div className="awsui-util-label">{record.title}</div>
                      <div>{record.data}</div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </ColumnLayout>
        )}
      </div>

      <div className="awsui-util-container">
        <div className="awsui-util-container-header">
          <h2>APN Immersion Days Delivered by Month</h2>
          <div className="awsui-util-container-header-description">
            Last 12 months
          </div>
        </div>
        {loading && (
          <div>
            <span className="awsui-util-status-inactive">
              <Spinner /> Loading
            </span>
          </div>
        )}
        {!loading && !objIsEmpty(dashboardData) && (
          <ChartistGraph
            className={`.ct-series-a`}
            data={dashboardData.deliveredByMonth.data}
            type="Bar"
            options={dashboardData.deliveredByMonth.options}
            responsiveOptions={dashboardData.deliveredByMonth.responsiveOptions}
            listener={dashboardData.deliveredByMonth.animation}
          />
        )}
      </div>
    </>
  );
};

export default HomeDashboard;
