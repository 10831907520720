import React, { useState, useReducer } from "react";
import { useSelector } from "react-redux";

import { Auth as AmplifyAuth } from "aws-amplify";
import { formReducer } from "../../../reducers";
import { formIsValid } from "../../../utils";

import logo from "../../../img/AWS_APN_PartnerNetwork_ImmersionDay_Dark.png";
import "../../../styles/auth.scss";

import {
  Button,
  ColumnLayout,
  Form,
  FormField,
  FormSection,
  Input,
  Modal
} from "@amzn/awsui-components-react";

const DEFAULT_STATE = {
  fieldValues: {
    email: ""
  },
  fieldStates: {
    email: ""
  },
  requiredFields: ["email"]
};

const AlertModal = ({ onClose }) => {
  return (
    <Modal
      header="Thanks!"
      visible={true}
      footer={
        <span className="awsui-util-f-r">
          <Button variant="primary" onClick={() => onClose()}>
            Ok
          </Button>
        </span>
      }
    >
      <p>
        If this is a valid email address, we will send you a verfication code
        along with instructions on how to reset your password.
      </p>
    </Modal>
  );
};

const ForgotPasswordForm = ({ history }) => {
  // Check if user is alread logged in and redirect to home
  const currentUser = useSelector(state => state.app.currentUser);
  if (currentUser) history.push("/");

  // Component states
  const [form, setForm] = useReducer(formReducer, DEFAULT_STATE);
  const [formErrorText, setFormErrorText] = useState(null);
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(null);

  const submitForm = async () => {
    setLoading(true);
    let input = { ...form.fieldValues };

    try {
      await AmplifyAuth.forgotPassword(input.email);
    } catch (e) {}

    // Regardless of outcome, notify and redirect to change password page
    setModal(
      <AlertModal onClose={() => history.push("/auth/change-password")} />
    );
  };

  return (
    <div id="mainPanel" className="auth-form">
      {modal}
      <Form
        header={
          <img
            src={logo}
            className="auth-logo"
            alt="APN Immersion Day Forgot Password Form"
          />
        }
        errorText={formErrorText}
        actions={
          <div>
            <Button
              variant="primary"
              loading={loading}
              onClick={() => {
                if (formIsValid(form)) {
                  setFormErrorText("");
                  submitForm();
                } else {
                  setFormErrorText(
                    "Please fill out all of the required form fields."
                  );
                }
              }}
            >
              Submit
            </Button>
          </div>
        }
      >
        <FormSection id="forgot-password-panel" header="Forgot password">
          <ColumnLayout>
            <div data-awsui-column-layout-root={true}>
              <FormField
                label="Email Address"
                stretch={true}
                errorText={
                  form.fieldStates.email === "" || form.fieldStates.email
                    ? ""
                    : "Must be a valid email address."
                }
              >
                <Input
                  id="email"
                  value={form.fieldValues.email}
                  onInput={({ detail }) => {
                    setForm({
                      type: "email",
                      required: true,
                      payload: {
                        field: "email",
                        value: detail.value
                      }
                    });
                  }}
                />
              </FormField>
            </div>
          </ColumnLayout>
        </FormSection>
      </Form>
    </div>
  );
};

export default ForgotPasswordForm;
