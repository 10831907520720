/****************************************************
 * Actions for breadcrumbs
 ***************************************************/

export const updateBreadCrumbs = items => {
  return {
    type: "UPDATE_BREADCRUMBS",
    payload: items
  };
};

export const addBreadCrumb = item => {
  return {
    type: "ADD_BREADCRUMB",
    payload: item
  };
};

export const popBreadCrumb = item => {
  return {
    type: "POP_BREADCRUMB",
    payload: item
  };
};

/****************************************************
 * Actions for flash messages
 ***************************************************/

export const addFlash = item => {
  return {
    type: "ADD_FLASH",
    payload: item
  };
};

export const removeFlash = item => {
  return {
    type: "REMOVE_FLASH",
    payload: item
  };
};

export const resetFlash = () => {
  return {
    type: "RESET",
    payload: null
  };
};

/****************************************************
 * Actions for current user
 ***************************************************/

export const setCurrentUser = payload => {
  return {
    type: "USER_SETCURRENTUSER",
    payload
  };
};

export const setUserIsAuthenticating = payload => {
  return {
    type: "USER_AUTHENTICATING",
    payload
  };
};

export const setUserIsAuthenticated = payload => {
  return {
    type: "USER_AUTHENTICATED",
    payload
  };
};

export const userReset = () => {
  return {
    type: "USER_RESET",
    payload: null
  };
};
