import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { updateBreadCrumbs } from "../../actions";
import { loadMarkdownFromS3 } from "../../utils";
import { Storage } from "aws-amplify";
import { Spinner } from "@amzn/awsui-components-react";
import Markdown from "react-markdown";
import ReactPlayer from "react-player";

const rootPath = "home/resources/virtual";
const contentPath = "markdown/virtual-event-guidance.md";

const VirtualGuidancePage = () => {
  const dispatch = useDispatch();

  const [pageConfig, setPageConfig] = useState(false);
  const [mediaLink, setMediaLink] = useState(false);
  const [loading, setLoading] = useState(false);

  // Breadcrumbs
  useEffect(() => {
    dispatch(
      updateBreadCrumbs([
        {
          text: "APN Immersion Day",
          href: "/"
        },
        {
          text: "Virtual Event Guidance",
          href: "/resources/virtual-guidance"
        }
      ])
    );
  }, [dispatch]);

  // Config loader
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      setPageConfig(await loadMarkdownFromS3(`${rootPath}/${contentPath}`));

      setMediaLink(
        await Storage.get(`${rootPath}/media/virtual-event-guidance.mp4`, {
          expires: 5400
        })
      );

      setLoading(false);
    };
    fetchData();
  }, []);

  if (loading) {
    return (
      <span>
        <Spinner size="large" />
      </span>
    );
  } else {
    return (
      <div className="awsui-util-container">
        <div className="awsui-util-container-header">
          <h2>Virtual Event Guidance</h2>
        </div>
        <ReactPlayer url={mediaLink} controls={true} width="95%" height="95%" />
        <div>
          <Markdown>{pageConfig}</Markdown>
        </div>
      </div>
    );
  }
};

export default VirtualGuidancePage;
