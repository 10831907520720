import React, { useState, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";

import { API, graphqlOperation } from "aws-amplify";
import { createFeedback } from "../../../graphql/mutations";
import { formReducer } from "../../../reducers";
import { formIsValid } from "../../../utils";
import { addFlash } from "../../../actions";

import {
  Button,
  ColumnLayout,
  Form,
  FormField,
  FormSection,
  Textarea
} from "@amzn/awsui-components-react";

const DEFAULT_STATE = {
  fieldValues: {
    feedback: ""
  },
  fieldStates: {
    feedback: ""
  },
  requiredFields: ["feedback"]
};

const FeedbackForm = () => {
  const currentUser = useSelector(state => state.app.currentUser);

  // Component states
  const [form, setForm] = useReducer(formReducer, DEFAULT_STATE);
  const [formErrorText, setFormErrorText] = useState(null);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const submitForm = async () => {
    setLoading(true);
    let input = { ...form.fieldValues };

    try {
      // Save the feedback to DynamoDB
      await API.graphql(
        graphqlOperation(createFeedback, {
          input: {
            userId: currentUser.id,
            feedback: input.feedback
          }
        })
      );

      dispatch(
        addFlash({
          type: "success",
          content: "Feedback submitted."
        })
      );
      setForm({
        type: "initialize",
        payload: DEFAULT_STATE
      });
      setLoading(false);
    } catch (e) {
      dispatch(
        addFlash({
          type: "error",
          header: "An error occurred",
          content: "Please try again later."
        })
      );
      setLoading(false);
    }
  };

  return (
    <div id="mainPanel">
      <Form
        errorText={formErrorText}
        actions={
          <div>
            <Button
              variant="primary"
              loading={loading}
              onClick={() => {
                if (formIsValid(form)) {
                  setFormErrorText("");
                  submitForm();
                } else {
                  setFormErrorText(
                    "Please fill out all of the required form fields."
                  );
                }
              }}
            >
              Submit
            </Button>
          </div>
        }
      >
        <FormSection
          id="feedback-panel"
          header="Leave Feedback"
          description={
            <span>
              Leave feedback on the website here.
              <br />
              <br />
              For program related questions, please email us at{" "}
              <a href="mailto:apn-immersionday@amazon.com">
                apn-immersionday@amazon.com
              </a>
            </span>
          }
        >
          <ColumnLayout>
            <div data-awsui-column-layout-root={true}>
              <FormField
                errorText={
                  form.fieldStates.feedback === "" || form.fieldStates.feedback
                    ? ""
                    : "This is a required field."
                }
              >
                <Textarea
                  id="feedback"
                  placeholder="Leave feedback on the website here."
                  rows={6}
                  value={form.fieldValues.feedback}
                  onInput={({ detail }) => {
                    setForm({
                      type: "string",
                      required: true,
                      payload: {
                        field: "feedback",
                        value: detail.value
                      }
                    });
                  }}
                />
              </FormField>
            </div>
          </ColumnLayout>
        </FormSection>
      </Form>
    </div>
  );
};

export default FeedbackForm;
