import React, { useState, useEffect } from "react";

import { API, graphqlOperation } from "aws-amplify";
import { getPartnerAwsTeam } from "../../../graphql/apnid-queries";

import { Table } from "@amzn/awsui-components-react";

import { AWSTEAM_COLUMN_DEFINITIONS } from "./OrganizationAwsTeamTableConfig";

const PartnerAwsTeamTable = ({ partnerId }) => {
  const [loading, setLoading] = useState(false);
  const [awsTeam, setAwsTeam] = useState([]);

  // Get data on component load
  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line

  // Fetch data from GraphQL API
  const fetchData = async () => {
    setLoading(true);
    const result = await API.graphql(
      graphqlOperation(getPartnerAwsTeam, { id: partnerId })
    );
    let awsTeam = result.data.getPartner.awsTeam;
    if (!awsTeam) awsTeam = [];
    setAwsTeam(awsTeam);
    setLoading(false);
  };

  return (
    <div>
      <div className="awsui-util-mb-l">
        <Table
          columnDefinitions={AWSTEAM_COLUMN_DEFINITIONS}
          loading={loading}
          loadingText="Loading..."
          items={awsTeam}
          header={
            <div className="awsui-util-action-stripe">
              <div className="awsui-util-action-stripe-title">
                <h2>AWS Team</h2>
              </div>
            </div>
          }
          empty={
            <div className="awsui-util-t-c">
              <div className="awsui-util-pt-s awsui-util-mb-xs">
                <b>No AWS team</b>
              </div>
              <p className="awsui-util-mb-s">No AWS Team to display.</p>
            </div>
          }
        />
      </div>
    </div>
  );
};

export default PartnerAwsTeamTable;
