import React, { useState, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";

import { API, graphqlOperation, Auth as AmplifyAuth } from "aws-amplify";
import { updateUser } from "../../../graphql/mutations";
import { setCurrentUser, setUserIsAuthenticated } from "../../../actions";
import { formReducer } from "../../../reducers";
import { formIsValid } from "../../../utils";

import logo from "../../../img/AWS_APN_PartnerNetwork_ImmersionDay_Dark.png";
import "../../../styles/auth.scss";

import {
  Alert,
  Button,
  ColumnLayout,
  Form,
  FormField,
  FormSection,
  Input,
  Checkbox
} from "@amzn/awsui-components-react";

const DEFAULT_STATE = {
  fieldValues: {
    firstName: "",
    lastName: "",
    title: "",
    email: "",
    registrationCode: "",
    newPassword: "",
    termsAndConditions: false
  },
  fieldStates: {
    firstName: "",
    lastName: "",
    title: "",
    email: "",
    registrationCode: "",
    newPassword: "",
    termsAndConditions: false
  },
  requiredFields: [
    "firstName",
    "lastName",
    "title",
    "email",
    "registrationCode",
    "newPassword",
    "termsAndConditions"
  ]
};

/**
 * Save the user profile to DynamoDB via the updateUser API.
 * Then, update the user profile in the app state.
 * @param {AmplifyAuth} user
 * @param {String} email
 * @param {String} firstName
 * @param {String} lastName
 * @param {String} title
 */
const saveUserProfile = async (user, email, firstName, lastName, title) => {
  // Set the user profile object values
  let userProfile = {
    id: user.username,
    email,
    firstName,
    lastName,
    title,
    userStatus: "active"
  };

  try {
    // Save the user profile in DynamoDB
    const updateUserResult = await API.graphql(
      graphqlOperation(updateUser, { input: userProfile })
    );

    // Append the user group information to the application's user profile object
    const userSession = await AmplifyAuth.currentSession();
    let profileData = updateUserResult.data.updateUser;
    profileData.groups = userSession.accessToken.payload["cognito:groups"];

    // Return the user profile
    return profileData;
  } catch (e) {
    console.log("error updating user in DynamoDB");
    console.log(e);
  }
};

const RegistrationForm = ({ history }) => {
  // Check if user is alread logged in and redirect to home
  const currentUser = useSelector(state => state.app.currentUser);
  if (currentUser) history.push("/");

  // Component states
  const [form, setForm] = useReducer(formReducer, DEFAULT_STATE);
  const [formErrorText, setFormErrorText] = useState(null);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const submitForm = async () => {
    setLoading(true);
    let input = { ...form.fieldValues };

    AmplifyAuth.signIn(input.email, input.registrationCode)
      .then(user => {
        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          AmplifyAuth.completeNewPassword(user, input.newPassword, {
            email: input.email
          })
            .then(async authedUser => {
              const userProfile = await saveUserProfile(
                authedUser,
                input.email,
                input.firstName,
                input.lastName,
                input.title
              );
              dispatch(setCurrentUser(userProfile));
              dispatch(setUserIsAuthenticated(true));
              history.push("/");
            })
            .catch(e => {
              let errMsg =
                e.code === "InvalidPasswordException"
                  ? e.message
                  : "An error occurred, please wait a moment and try again.";

              setFormErrorText(errMsg);
              setLoading(false);
            });
        } else {
          // other situations
        }
      })
      .catch(e => {
        setFormErrorText("The email address or verification code is invalid. Please try again."); // eslint-disable-line
        setLoading(false);
      });
  };

  return (
    <div id="mainPanel" className="auth-form">
      <Form
        header={
          <img
            src={logo}
            className="auth-logo"
            alt="APN Immersion Day Registration Form"
          />
        }
        errorText={formErrorText}
        actions={
          <div>
            <Button
              variant="primary"
              loading={loading}
              onClick={() => {
                if (formIsValid(form)) {
                  setFormErrorText("");
                  submitForm();
                } else {
                  setFormErrorText(
                    "Please fill out all of the required form fields."
                  );
                }
              }}
            >
              Submit
            </Button>
          </div>
        }
      >
        <FormSection id="registration-form-panel" header="Registration Form">
          <ColumnLayout columns={2}>
            <div data-awsui-column-layout-root={true}>
              <FormField
                label="First Name"
                errorText={
                  form.fieldStates.firstName === "" ||
                  form.fieldStates.firstName
                    ? ""
                    : "This field is required."
                }
              >
                <Input
                  id="firstName"
                  value={form.fieldValues.firstName}
                  onInput={({ detail }) => {
                    setForm({
                      type: "string",
                      required: true,
                      payload: {
                        field: "firstName",
                        value: detail.value
                      }
                    });
                  }}
                />
              </FormField>
              <FormField
                label="Last Name"
                errorText={
                  form.fieldStates.lastName === "" || form.fieldStates.lastName
                    ? ""
                    : "This field is required."
                }
              >
                <Input
                  id="lastName"
                  value={form.fieldValues.lastName}
                  onInput={({ detail }) => {
                    setForm({
                      type: "string",
                      required: true,
                      payload: {
                        field: "lastName",
                        value: detail.value
                      }
                    });
                  }}
                />
              </FormField>
            </div>
          </ColumnLayout>
          <ColumnLayout>
            <div data-awsui-column-layout-root={true}>
              <FormField
                label="Business Title"
                stretch={true}
                errorText={
                  form.fieldStates.title === "" || form.fieldStates.title
                    ? ""
                    : "This field is required."
                }
              >
                <Input
                  id="title"
                  value={form.fieldValues.title}
                  onInput={({ detail }) => {
                    setForm({
                      type: "string",
                      required: true,
                      payload: {
                        field: "title",
                        value: detail.value
                      }
                    });
                  }}
                />
              </FormField>
              <FormField
                label="Email Address"
                stretch={true}
                errorText={
                  form.fieldStates.email === "" || form.fieldStates.email
                    ? ""
                    : "Must be a valid email address."
                }
              >
                <Input
                  id="email"
                  value={form.fieldValues.email}
                  onInput={({ detail }) => {
                    setForm({
                      type: "email",
                      required: true,
                      payload: {
                        field: "email",
                        value: detail.value
                      }
                    });
                  }}
                />
              </FormField>
              <FormField
                label="Registration Code"
                stretch={true}
                errorText={
                  form.fieldStates.registrationCode === "" ||
                  form.fieldStates.registrationCode
                    ? ""
                    : "This field is required."
                }
              >
                <Input
                  id="registrationCode"
                  value={form.fieldValues.registrationCode}
                  onInput={({ detail }) => {
                    setForm({
                      type: "string",
                      required: true,
                      payload: {
                        field: "registrationCode",
                        value: detail.value
                      }
                    });
                  }}
                />
              </FormField>
              <FormField
                label="New Password"
                stretch={true}
                errorText={
                  form.fieldStates.newPassword === "" ||
                  form.fieldStates.newPassword
                    ? ""
                    : "This field is required."
                }
              >
                <Input
                  id="newPassword"
                  type="password"
                  onInput={({ detail }) => {
                    setForm({
                      type: "password",
                      required: true,
                      payload: {
                        field: "newPassword",
                        value: detail.value
                      }
                    });
                  }}
                />
              </FormField>
              <FormField
                stretch={true}
                hintText={
                  <div>
                    {!form.fieldStates.termsAndConditions && (
                      <div>
                        <br />
                        <Alert type="warning">
                          You must agree to the terms and conditions.
                        </Alert>
                      </div>
                    )}
                  </div>
                }
              >
                <Checkbox
                  id="termsAndConditions"
                  checked={form.fieldValues.termsAndConditions}
                  onChange={({ detail }) => {
                    setForm({
                      type: "checkbox",
                      required: true,
                      payload: {
                        field: "termsAndConditions",
                        value: detail
                      }
                    });
                  }}
                >
                  I agree to the{" "}
                  <a
                    href="https://aws.amazon.com/partners/terms-and-conditions/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    terms and conditions
                  </a>
                </Checkbox>
              </FormField>
            </div>
          </ColumnLayout>
        </FormSection>
      </Form>
    </div>
  );
};

export default RegistrationForm;
