import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { updateBreadCrumbs } from "../../actions";

import { Tabs } from "@amzn/awsui-components-react";

import OrganizationAwsTeamTable from "./components/OrganizationAwsTeamTable";
import OrganizationDetails from "./components/OrganizationDetails";
import OrganizationExecSponsorsTable from "./components/OrganizationExecSponsorsTable";
import OrganizationUsersTable from "./components/OrganizationUsersTable";

const OrganizationPage = props => {
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.app.currentUser);
  const partnerId = currentUser.partner.id;

  useEffect(() => {
    dispatch(
      updateBreadCrumbs([
        {
          text: "APN Immersion Day",
          href: "/"
        },
        {
          text: "My Organization",
          href: "/organization"
        }
      ])
    );
  }, [dispatch]);

  const [activeTabId, setActiveTabId] = useState("awsTeam");

  const tabs = [
    {
      label: "AWS Team",
      id: "awsTeam",
      content: <OrganizationAwsTeamTable partnerId={partnerId} />
    },
    {
      label: "Executive Sponsors",
      id: "execSponsors",
      content: <OrganizationExecSponsorsTable partnerId={partnerId} />
    },
    {
      label: "Users",
      id: "users",
      content: <OrganizationUsersTable partnerId={partnerId} />
    }
  ];

  return (
    <>
      <OrganizationDetails partnerId={partnerId} history={props.history} />
      <Tabs
        tabs={tabs}
        activeTabId={activeTabId}
        onChange={({ detail }) => setActiveTabId(detail.activeTabId)}
      />
    </>
  );
};

export default OrganizationPage;
