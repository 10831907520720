import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { updateBreadCrumbs } from "../../actions";

import ChangePasswordForm from "./components/ChangePasswordForm";

const ChangePasswordPage = props => {
  const { history } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateBreadCrumbs([{}]));
  }, [dispatch]);

  return <ChangePasswordForm history={history} />;
};

export default ChangePasswordPage;
