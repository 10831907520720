import React from "react";

// Route Helpers
import { Switch } from "react-router-dom";
import { PublicRoute, PrivateRoute } from "./components/Routers";

// Pages
import {
  ChangePasswordPage,
  ForgotPasswordPage,
  LoginPage,
  RegistrationPage,
  ResendConfirmationPage
} from "./pages/Auth";
import { Logout } from "./components/Auth";
import CoursePage from "./pages/Course";
import {
  CompleteEventsPage,
  EditEventDetailPage,
  EventsCalendarPage,
  EventDetailPage,
  ScheduleAnEventPage,
  ScheduledEventsPage
} from "./pages/Events";
import FeedbackPage from "./pages/Feedback";
import HomePage from "./pages/Home";
import {
  EditOrganizationDetailsPage,
  OrganizationPage
} from "./pages/Organization";
import {
  CommonResourcesPage,
  FaqPage,
  VirtualGuidancePage
} from "./pages/Resources";
import UserProfilePage from "./pages/UserProfile";

const AppRoutes = ({ props }) => {
  return (
    <Switch>
      {/* PUBLIC ROUTES */}

      <PublicRoute
        path="/auth/change-password"
        component={ChangePasswordPage}
        props={props}
      />
      <PublicRoute
        path="/auth/forgot-password"
        component={ForgotPasswordPage}
        props={props}
      />
      <PublicRoute
        path="/auth/register-page"
        component={RegistrationPage}
        props={props}
      />
      <PublicRoute
        path="/auth/resend-confirmation"
        component={ResendConfirmationPage}
        props={props}
      />
      <PublicRoute path="/auth/login" component={LoginPage} props={props} />
      <PublicRoute path="/auth/logout" component={Logout} props={props} />

      {/* PRIVATE ROUTES */}

      <PrivateRoute
        path="/events/calendar"
        component={EventsCalendarPage}
        props={props}
      />

      <PrivateRoute
        path="/events/complete"
        component={CompleteEventsPage}
        props={props}
      />

      <PrivateRoute
        path="/events/schedule"
        component={ScheduleAnEventPage}
        props={props}
      />

      <PrivateRoute
        path="/events/scheduled"
        component={ScheduledEventsPage}
        props={props}
      />

      <PrivateRoute
        path="/events/:eventId/edit"
        component={EditEventDetailPage}
        props={props}
      />

      <PrivateRoute
        path="/events/:eventId"
        component={EventDetailPage}
        props={props}
      />

      <PrivateRoute
        path="/user-profile"
        component={UserProfilePage}
        props={props}
      />

      <PrivateRoute
        path="/organization/edit"
        component={EditOrganizationDetailsPage}
        props={props}
      />

      <PrivateRoute
        path="/organization"
        component={OrganizationPage}
        props={props}
      />

      <PrivateRoute
        path="/courses/:course"
        component={CoursePage}
        props={props}
      />

      <PrivateRoute
        path="/resources/virtual-guidance"
        component={VirtualGuidancePage}
        props={props}
      />

      <PrivateRoute path="/resources/faq" component={FaqPage} props={props} />

      <PrivateRoute
        path="/resources/:resource"
        component={CommonResourcesPage}
        props={props}
      />

      <PrivateRoute path="/feedback" component={FeedbackPage} props={props} />

      <PrivateRoute path="/" component={HomePage} props={props} />
    </Switch>
  );
};

export default AppRoutes;
