import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { addFlash, updateBreadCrumbs } from "../../actions";

import EditOrganizationDetailsForm from "./components/EditOrganizationDetailsForm";

import "../../styles/partnerStyles.scss";

const EditPartnerDetailPage = props => {
  const { history } = props;
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.app.currentUser);
  const isExecSponsor =
    currentUser.groups.indexOf("PartnerExecutiveSponsor") > -1 ? true : false;
  const partnerId = currentUser.partner.id;

  // Only executive sponsors should be able to edit the organization
  if (!isExecSponsor) {
    dispatch(
      addFlash({
        type: "error",
        content: "Only Executive Sponsors may edit the Organization Details."
      })
    );
    history.push("/organization");
  }

  useEffect(() => {
    dispatch(
      updateBreadCrumbs([
        {
          text: "APN Immersion Day",
          href: "/"
        },
        {
          text: "My Organization",
          href: "/organization"
        },
        {
          text: "Edit",
          href: "/organization/edit"
        }
      ])
    );
  }, [dispatch, partnerId]);

  return (
    <>
      <EditOrganizationDetailsForm partnerId={partnerId} history={history} />
    </>
  );
};

export default EditPartnerDetailPage;
