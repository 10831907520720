import React, { useState, useEffect } from "react";

import { API, graphqlOperation } from "aws-amplify";
import { getPartnerUsers2 } from "../../../graphql/apnid-queries";

import { Table, Button } from "@amzn/awsui-components-react";

import { EXECSPONSORS_COLUMN_DEFINITIONS } from "./OrganizationExecSponsorsTableConfig";

const PartnerExecSponsorsTable = ({ partnerId }) => {
  const [loading, setLoading] = useState(false);
  const [execSponsors, setExecSponsors] = useState([]);

  // Get data on component load
  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line

  // Fetch data from GraphQL API
  const fetchData = async () => {
    setLoading(true);
    const userFilter = {
      execSponsor: {
        eq: true
      }
    };
    const result = await API.graphql(
      graphqlOperation(getPartnerUsers2, { id: partnerId, userFilter })
    );
    let execSponsors = result.data.getPartner.users.items;
    setExecSponsors(execSponsors);
    setLoading(false);
  };

  return (
    <div className="awsui-util-mb-l">
      <Table
        columnDefinitions={EXECSPONSORS_COLUMN_DEFINITIONS}
        loading={loading}
        loadingText="Loading..."
        items={execSponsors}
        header={
          <div className="awsui-util-action-stripe">
            <div className="awsui-util-action-stripe-title">
              <h2>Executive Sponsors</h2>
            </div>
            <div className="awsui-util-action-stripe-group">
              <Button icon="refresh" onClick={() => fetchData()} />
            </div>
          </div>
        }
        empty={
          <div className="awsui-util-t-c">
            <div className="awsui-util-pt-s awsui-util-mb-xs">
              <b>No Executive Sponsors</b>
            </div>
            <p className="awsui-util-mb-s">No Executive Sponsors to display.</p>
          </div>
        }
      />
    </div>
  );
};

export default PartnerExecSponsorsTable;
