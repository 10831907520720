import React from "react";

import { Icon, Table } from "@amzn/awsui-components-react";

import { COLUMN_DEFINITIONS } from "./CsatResultsTableConfig.jsx";

const CsatResultsTable = ({ csatFeedback, loading }) => {
  return (
    <Table
      columnDefinitions={COLUMN_DEFINITIONS}
      items={csatFeedback}
      loading={loading}
      loadingText="Loading ..."
      header={
        <div className="awsui-util-action-stripe">
          <div className="awsui-util-action-stripe-title">
            <h2>CSAT Feedback</h2>
            <br />
            <p>
              <span className="awsui-util-status-info">
                <Icon name="status-info" /> Comments for questions other than
                the <b>Overall</b> category are only captured for dissatisfied
                responses.
              </span>
            </p>
          </div>
        </div>
      }
      empty={
        <div className="awsui-util-t-c">
          <div className="awsui-util-pt-s awsui-util-mb-xs">
            <b>No CSAT results</b>
          </div>
          <p className="awsui-util-mb-s">No CSAT results to display.</p>
        </div>
      }
    />
  );
};

export default CsatResultsTable;
