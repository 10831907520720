import React from "react";
import { Route } from "react-router-dom";
import { useSelector } from "react-redux";

export function PrivateRoute({
  component: Component,
  props: childProps,
  ...rest
}) {
  const userIsAuthenticated = useSelector(
    state => state.app.userIsAuthenticated
  );

  if (userIsAuthenticated) {
    return (
      <Route
        {...rest}
        render={renderProps => <Component {...renderProps} {...childProps} />}
      />
    );
  } else window.location.href = "/auth/login";
}

export function PublicRoute({
  component: Component,
  props: childProps,
  ...rest
}) {
  return (
    <Route
      {...rest}
      render={renderProps => <Component {...renderProps} {...childProps} />}
    />
  );
}

export default null;
