import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { API, graphqlOperation } from "aws-amplify";
import { listEventsByPartnerByDate } from "../../../graphql/apnid-queries";

import { Spinner } from "@amzn/awsui-components-react";

import { objIsEmpty } from "../../../utils";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/bootstrap/main.css";

// Calendar constants
const now = new Date();
const y = now.getFullYear();
const m = now.getMonth();
const d = now.getDate();
const today = new Date(y, m, d);

const EventsCalendar = ({ history }) => {
  const currentUser = useSelector(state => state.app.currentUser);
  const partnerId = currentUser.partner.id;

  const [loading, setLoading] = useState(false);
  const [events, setEvents] = useState(false);

  const calendarComponentRef = React.createRef();

  // Get data on page load
  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line

  // Fetch data from GraphQL API
  const fetchData = async () => {
    setLoading(true);
    const filter = { status: { ne: "Canceled" } };
    const result = await API.graphql(
      graphqlOperation(listEventsByPartnerByDate, {
        partnerId,
        limit: 100000,
        filter
      })
    );
    setEvents(formatCalendarData(result.data.eventsByPartnerByDate.items));
    setLoading(false);
  };

  // Format the events for calendar view
  const formatCalendarData = allEvents => {
    let calen = [];
    for (let i = 0; i < allEvents.length; i += 1) {
      let eventDate = new Date(allEvents[i].date);
      eventDate.setHours(0);
      eventDate.setMinutes(0);
      eventDate.setSeconds(0);

      calen.push({
        id: allEvents[i].id,
        title: allEvents[i].topic,
        start: eventDate,
        end: eventDate,
        allDay: true,
        url: `/events/${allEvents[i].id}`,
        backgroundColor: getEventColor(eventDate),
        borderColor: getEventColor(eventDate),
        textColor: "white"
      });
    }
    return calen;
  };

  /**
   * Choose the event color based on its date
   * @param {Date} eventDate
   */
  const getEventColor = eventDate => {
    if (eventDate < today) return "#687078";
    else if (eventDate > today) return "#0073bb";
    else return "#1d8102";
  };

  return (
    <>
      <div className="awsui-util-container">
        <div className="awsui-util-container-header">
          <div className="awsui-util-action-stripe">
            <div className="awsui-util-action-stripe-title">
              <h2>Events Calendar</h2>
            </div>
          </div>
        </div>
        {loading && (
          <div>
            <span className="awsui-util-status-inactive">
              <Spinner /> Loading
            </span>
          </div>
        )}
        {!loading && !objIsEmpty(events) && (
          <FullCalendar
            defaultView="dayGridMonth"
            plugins={[dayGridPlugin]}
            events={events}
            eventClick={info => {
              info.jsEvent.preventDefault();
              history.push(info.event.url);
            }}
            ref={calendarComponentRef}
            weekends={false}
            height="auto"
          />
        )}
      </div>
    </>
  );
};

export default EventsCalendar;
