// import React from "react";
import { useDispatch } from "react-redux";

// aws, amplify, and util components
import { Auth as AmplifyAuth } from "aws-amplify";

import { setUserIsAuthenticated, userReset } from "../../actions";

export const Logout = async () => {
  const dispatch = useDispatch();
  dispatch(userReset());
  dispatch(setUserIsAuthenticated(false));
  await AmplifyAuth.signOut();
  window.location.href = "/auth/login";
  return null;
};
