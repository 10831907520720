import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { updateBreadCrumbs } from "../../actions";

import ResendConfirmationForm from "./components/ResendConfirmationForm";

const ResendConfirmationPage = props => {
  const { history, location } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateBreadCrumbs([{}]));
  }, [dispatch]);

  return <ResendConfirmationForm history={history} location={location} />;
};

export default ResendConfirmationPage;
