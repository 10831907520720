// app-wide shared reducers
import app from "./app";
import { combineReducers, createStore } from "redux";

// form reducers
import formReducer from "./formReducer";

export { formReducer };

const rootReducer = combineReducers({
  app
});

export const store = createStore(
  rootReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
