import { formatListItems, certificationLabelLookup } from "../../../utils";

export const USERS_COLUMN_DEFINITIONS = [
  {
    id: "id",
    header: () => "ID",
    cell: item => item.id,
    minWidth: "100px",
    allowLineWrap: true
  },
  {
    id: "name",
    header: () => "Name",
    cell: item =>
      item.userStatus === "invited"
        ? "<invite pending>"
        : `${item.firstName} ${item.lastName}`,
    minWidth: "175px",
    allowLineWrap: true
  },
  {
    id: "email",
    header: () => "Email",
    cell: item => item.email,
    minWidth: "175px",
    allowLineWrap: false
  },
  {
    id: "title",
    header: () => "Title",
    cell: item => item.title,
    minWidth: "100px",
    allowLineWrap: true
  },
  {
    id: "certifications",
    header: () => "Certifications",
    cell: item =>
      item.certifications
        ? item.certifications.map(certificationLabelLookup).map(formatListItems)
        : "-",
    minWidth: "350px",
    allowLineWrap: true
  },
  {
    id: "coverageAreas",
    header: () => "Coverage Areas",
    cell: item =>
      item.coverageAreas ? item.coverageAreas.map(formatListItems) : "-",
    minWidth: "200px",
    allowLineWrap: false
  }
];

export const USERS_SORTABLE_COLUMNS = [
  { id: "name", field: "firstName" },
  { id: "email", field: "email" }
];

export const USERS_CONTENT_SELECTOR_OPTIONS = [
  {
    label: "Table settings",
    options: [
      { id: "id", label: "ID", editable: false, visible: false },
      { id: "name", label: "Name", editable: true, visible: true },
      { id: "email", label: "Email", editable: true, visible: true },
      { id: "title", label: "Title", editable: true, visible: true },
      {
        id: "certifications",
        label: "Certifications",
        editable: true,
        visible: true
      },
      {
        id: "coverageAreas",
        label: "Coverage Area",
        editable: true,
        visible: true
      }
    ]
  }
];

export const USERS_PAGE_SELECTOR_OPTIONS = [
  { value: 10, label: "10" },
  { value: 30, label: "30" },
  { value: 50, label: "50" }
];
