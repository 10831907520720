/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { updateBreadCrumbs } from "../../actions";
import { loadYamlFromS3 } from "../../utils";

import { Spinner, Tabs } from "@amzn/awsui-components-react";

import ResourceTable from "./components/ResourceTable";

const CommonResourcesPage = (props) => {
  const { match } = props;
  const { resource } = match.params;

  const rootPath = `home/resources/${resource}`;
  const contentPath = `yaml/${resource}-resources-2.yaml`;

  const dispatch = useDispatch();

  const [pageConfig, setPageConfig] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activeTabId, setActiveTabId] = useState("");

  // Breadcrumbs
  useEffect(() => {
    dispatch(
      updateBreadCrumbs([
        {
          text: "APN Immersion Day",
          href: "/"
        },
        {
          text: `${resource.charAt(0).toUpperCase() + resource.slice(1)} Resources`,
          href: `/resources/${resource}`
        }
      ])
    );
  }, [dispatch, resource]);

  // Config loader
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      let result = await loadYamlFromS3(
        `${rootPath}/${contentPath}`
      );

      // Inject the root path to each item
      result.tabs = result.tabs.map(tab => {
        let rows = tab.rows.map(item => ({
          ...item,
          rootPath
        }));
        return { ...tab, rows };
      });
      
      setActiveTabId(result.tabs[0].id);
      setPageConfig(result);
      setLoading(false);
    };
    fetchData();
  }, [resource]);

  // Page tabs
  const tabs = !pageConfig
    ? []
    : pageConfig.tabs.map(tab => ({
      label: tab.header,
      id: tab.id,
      content: (
        <ResourceTable
          header={tab.header}
          items={tab.rows}
          rootPath={rootPath}
        />
      )
    }));

  if (loading) {
    return (
      <span>
        <Spinner size="large" />
      </span>
    );
  } else {
    return (
      <Tabs
        tabs={tabs}
        variant="container"
        activeTabId={activeTabId}
        onChange={({ detail }) => setActiveTabId(detail.activeTabId)}
      />
    );
  }
};

export default CommonResourcesPage;
