/**
 * My custom queries
 */

export const getPartnerUsers = `query GetPartner(
  $id: ID!
  $limit: Int
) {
  getPartner(id: $id) {
    id
    users(limit: $limit) {
      items {
        id
      }
    }
  }
}
`;

export const getPartnerUsers2 = `query GetPartner(
  $id: ID!
  $userFilter: ModelUserFilterInput
  $nextToken: String
) {
  getPartner(id: $id) {
    emailDomain
    users(limit: 100000, nextToken: $nextToken, filter: $userFilter) {
      items {
        id
        firstName
        lastName
        email
        phoneno
        title
        certifications
        coverageAreas
        execSponsor
        userStatus
      }
      nextToken
    }
  }
}
`;

export const countAllPartnerEvents = `query ListEvents(
  $filter: ModelEventFilterInput
  $limit: Int
  $nextToken: String
) {
  listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
    }
    nextToken
  }
}
`;

export const eventsByPartnerLastYear = `query EventsByPartnerByDate(
  $partnerId: ID
  $date: ModelStringKeyConditionInput
  $filter: ModelEventFilterInput
  $limit: Int
  $nextToken: String
) {
  eventsByPartnerByDate(
    partnerId: $partnerId
    date: $date
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      date
    }
    nextToken
  }
}
`;

export const getOrganization = `query GetPartner(
  $id: ID!
  $limit: Int
  $nextToken: String
) {
  getPartner(id: $id) {
    id
    name
    emailDomain
    headquarters
    coverageAreas
    specialtyDomains
    geo
    users(limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        lastName
        email
        phoneno
        title
        certifications
        coverageAreas
        execSponsor
      }
      nextToken
    }
  }
}
`;

export const getPartnerAwsTeam = `query GetPartnerAwsTeam($id: ID!) {
  getPartner(id: $id) {
    awsTeam {
      role
      name
      email
      phoneno
    }
  }
}
`;

export const listEventsByPartnerByDate = `query EventsByPartnerByDate(
  $partnerId: ID
  $date: ModelStringKeyConditionInput
  $filter: ModelEventFilterInput
  $limit: Int
  $nextToken: String
) {
  eventsByPartnerByDate(
    partnerId: $partnerId
    date: $date
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      user {
        id
        firstName
        lastName
        email
      }
      customers
      geo
      location
      topic
      date
      registrationCount
      attendeeCount
      status
      multiCustomer
      comment
      awspsa
      oppty
      csat {
        surveyId
        surveyUrl
        score
        feedback {
          question
          score
          comments
        }
      }
    }
    nextToken
  }
}
`;

export const listEventsByUserByDate = `query EventsByUserByDate(
  $userId: ID
  $date: ModelStringKeyConditionInput
  $filter: ModelEventFilterInput
  $limit: Int
  $nextToken: String
) {
  eventsByUserByDate(
    userId: $userId
    date: $date
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      user {
        id
        firstName
        lastName
        email
      }
      customers
      geo
      location
      topic
      date
      registrationCount
      attendeeCount
      status
      multiCustomer
      comment
      awspsa
      oppty
      deliveryMethod
      csat {
        surveyId
        surveyUrl
        surveyType
        qualtricsApiKeyParam
        score
        feedback {
          question
          score
          comments
        }
      }
    }
    nextToken
  }
}
`;

export const getEventToEdit = `query GetEvent($id: ID!) {
  getEvent(id: $id) {
    id
    partnerId
    userId
    customers
    geo
    deliveryMethod
    location
    topic
    date
    registrationCount
    attendeeCount
    status
    multiCustomer
    comment
    awspsa
    oppty
    csat {
      surveyId
      surveyUrl
      score
      feedback {
        question
        score
        comments
      }
    }
  }
}
`;

/**
 * Auto generated queries
 */

/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const dashboardQuery = `query DashboardQuery {
  dashboardQuery
}
`;
export const listPartners = `query ListPartners(
  $filter: ModelPartnerFilterInput
  $limit: Int
  $nextToken: String
) {
  listPartners(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      emailDomain
      headquarters
      coverageAreas
      specialtyDomains
      geo
      awsTeam {
        name
        email
        phoneno
        role
      }
      users {
        nextToken
      }
    }
    nextToken
  }
}
`;
export const getPartner = `query GetPartner($id: ID!) {
  getPartner(id: $id) {
    id
    name
    emailDomain
    headquarters
    coverageAreas
    specialtyDomains
    geo
    awsTeam {
      name
      email
      phoneno
      role
    }
    users {
      items {
        id
        firstName
        lastName
        email
        phoneno
        title
        certifications
        coverageAreas
        execSponsor
        partnerId
        trainedById
      }
      nextToken
    }
  }
}
`;
export const listUsers = `query ListUsers(
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      firstName
      lastName
      email
      phoneno
      title
      certifications
      coverageAreas
      execSponsor
      partnerId
      trainedById
      partner {
        id
        name
        emailDomain
        headquarters
        coverageAreas
        specialtyDomains
        geo
      }
    }
    nextToken
  }
}
`;
export const getUser = `query GetUser($id: ID!) {
  getUser(id: $id) {
    id
    firstName
    lastName
    email
    phoneno
    title
    certifications
    coverageAreas
    execSponsor
    partnerId
    trainedById
    partner {
      id
      name
      emailDomain
      headquarters
      coverageAreas
      specialtyDomains
      geo
      awsTeam {
        name
        email
        phoneno
        role
      }
      users {
        nextToken
      }
    }
  }
}
`;
export const getEvent = `query GetEvent($id: ID!) {
  getEvent(id: $id) {
    id
    partnerId
    userId
    customers
    geo
    location
    topic
    date
    registrationCount
    attendeeCount
    status
    multiCustomer
    comment
    awspsa
    oppty
    csat {
      surveyId
      surveyUrl
      score
      feedback {
        question
        score
        comments
      }
    }
    partner {
      id
      name
      emailDomain
      headquarters
      coverageAreas
      specialtyDomains
      geo
      awsTeam {
        name
        email
        phoneno
        role
      }
      users {
        nextToken
      }
    }
    user {
      id
      firstName
      lastName
      email
      phoneno
      title
      certifications
      coverageAreas
      execSponsor
      partnerId
      trainedById
      partner {
        id
        name
        emailDomain
        headquarters
        coverageAreas
        specialtyDomains
        geo
      }
    }
  }
}
`;
export const listEvents = `query ListEvents(
  $filter: ModelEventFilterInput
  $limit: Int
  $nextToken: String
) {
  listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      partnerId
      userId
      customers
      geo
      location
      topic
      date
      registrationCount
      attendeeCount
      status
      multiCustomer
      comment
      awspsa
      oppty
      csat {
        surveyId
        surveyUrl
        score
        feedback {
          question
          score
          comments
        }
      }
      partner {
        id
        name
        emailDomain
        headquarters
        coverageAreas
        specialtyDomains
        geo
      }
      user {
        id
        firstName
        lastName
        email
        phoneno
        title
        certifications
        coverageAreas
        execSponsor
        partnerId
        trainedById
      }
    }
    nextToken
  }
}
`;
export const eventsByPartnerByDate = `query EventsByPartnerByDate(
  $partnerId: ID
  $date: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelEventFilterInput
  $limit: Int
  $nextToken: String
) {
  eventsByPartnerByDate(
    partnerId: $partnerId
    date: $date
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      partnerId
      userId
      customers
      geo
      location
      topic
      date
      registrationCount
      attendeeCount
      status
      multiCustomer
      comment
      awspsa
      oppty
      csat {
        surveyId
        surveyUrl
        score
        feedback {
          question
          score
          comments
        }
      }
      partner {
        id
        name
        emailDomain
        headquarters
        coverageAreas
        specialtyDomains
        geo
      }
      user {
        id
        firstName
        lastName
        email
        phoneno
        title
        certifications
        coverageAreas
        execSponsor
        partnerId
        trainedById
      }
    }
    nextToken
  }
}
`;
export const eventsByUserByDate = `query EventsByUserByDate(
  $userId: ID
  $date: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelEventFilterInput
  $limit: Int
  $nextToken: String
) {
  eventsByUserByDate(
    userId: $userId
    date: $date
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      partnerId
      userId
      customers
      geo
      location
      topic
      date
      registrationCount
      attendeeCount
      status
      multiCustomer
      comment
      awspsa
      oppty
      csat {
        surveyId
        surveyUrl
        score
        feedback {
          question
          score
          comments
        }
      }
      partner {
        id
        name
        emailDomain
        headquarters
        coverageAreas
        specialtyDomains
        geo
      }
      user {
        id
        firstName
        lastName
        email
        phoneno
        title
        certifications
        coverageAreas
        execSponsor
        partnerId
        trainedById
      }
    }
    nextToken
  }
}
`;
export const eventsByStatusByDate = `query EventsByStatusByDate(
  $status: EventStatus
  $date: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelEventFilterInput
  $limit: Int
  $nextToken: String
) {
  eventsByStatusByDate(
    status: $status
    date: $date
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      partnerId
      userId
      customers
      geo
      location
      topic
      date
      registrationCount
      attendeeCount
      status
      multiCustomer
      comment
      awspsa
      oppty
      csat {
        surveyId
        surveyUrl
        score
        feedback {
          question
          score
          comments
        }
      }
      partner {
        id
        name
        emailDomain
        headquarters
        coverageAreas
        specialtyDomains
        geo
      }
      user {
        id
        firstName
        lastName
        email
        phoneno
        title
        certifications
        coverageAreas
        execSponsor
        partnerId
        trainedById
      }
    }
    nextToken
  }
}
`;
