import React, { useEffect, useState, useReducer } from "react";
import { useSelector } from "react-redux";

import { Auth as AmplifyAuth } from "aws-amplify";
import { formReducer } from "../../../reducers";
import { formIsValid } from "../../../utils";

import logo from "../../../img/AWS_APN_PartnerNetwork_ImmersionDay_Dark.png";
import "../../../styles/auth.scss";

import qs from "qs";
import AWSAppSyncClient, { AUTH_TYPE } from "aws-appsync";
import gql from "graphql-tag";
import config from "../../../awsconfiguration.json";

import {
  Button,
  ColumnLayout,
  Form,
  FormField,
  FormSection,
  Input,
  Modal
} from "@amzn/awsui-components-react";

const DEFAULT_STATE = {
  fieldValues: {
    email: ""
  },
  fieldStates: {
    email: ""
  },
  requiredFields: ["email"]
};

const AlertModal = ({ onClose }) => {
  return (
    <Modal
      header="Thanks!"
      visible={true}
      footer={
        <span className="awsui-util-f-r">
          <Button variant="primary" onClick={() => onClose()}>
            Ok
          </Button>
        </span>
      }
    >
      <p>
        If this is a valid email address, you will receive a new invitation
        shortly.
      </p>
    </Modal>
  );
};

const ResendConfirmationForm = ({ history, location }) => {
  // Check if user is alread logged in and redirect to home
  const currentUser = useSelector(state => state.app.currentUser);
  if (currentUser) history.push("/");

  // Component states
  const [form, setForm] = useReducer(formReducer, DEFAULT_STATE);
  const [formErrorText, setFormErrorText] = useState(null);
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(null);

  useEffect(() => {
    const email = qs.parse(location.search, { ignoreQueryPrefix: true }).email;
    setForm({
      type: "email",
      payload: {
        field: "email",
        value: email
      }
    });
  }, [location]);

  const submitForm = async () => {
    setLoading(true);
    let input = { ...form.fieldValues };

    const unauthCredentials = await AmplifyAuth.currentCredentials();

    const IAM_UNAUTHCLIENT = new AWSAppSyncClient({
      url: config.aws_appsync_graphqlEndpoint,
      region: config.aws_appsync_region,
      auth: {
        type: AUTH_TYPE.AWS_IAM,
        credentials: {
          accessKeyId: unauthCredentials.data.Credentials.AccessKeyId,
          secretAccessKey: unauthCredentials.data.Credentials.SecretKey,
          sessionToken: unauthCredentials.data.Credentials.SessionToken
        }
      },
      offlineConfig: { keyPrefix: "iam" },
      disableOffline: true
    });

    const mutation = gql(
      `mutation($email: AWSEmail!) { resendCognitoConfirmation(input: { email: $email }) }`
    );

    try {
      // Invoke the API to re-send the confirmation code
      await IAM_UNAUTHCLIENT.mutate({
        mutation,
        variables: { email: input.email }
      });
    } catch (e) {}

    // Regardless of outcome, notify and redirect to registration page
    setModal(
      <AlertModal onClose={() => history.push("/auth/register-page")} />
    );
  };

  return (
    <div id="mainPanel" className="auth-form">
      {modal}
      <Form
        header={
          <img
            src={logo}
            className="auth-logo"
            alt="APN Immersion Day Resend Confirmation Form"
          />
        }
        errorText={formErrorText}
        actions={
          <div>
            <Button
              variant="primary"
              loading={loading}
              onClick={() => {
                if (formIsValid(form)) {
                  setFormErrorText("");
                  submitForm();
                } else {
                  setFormErrorText(
                    "Please fill out all of the required form fields."
                  );
                }
              }}
            >
              Submit
            </Button>
          </div>
        }
      >
        <FormSection
          id="resend-confirmation-panel"
          header="Resend confirmation email"
        >
          <ColumnLayout>
            <div data-awsui-column-layout-root={true}>
              <FormField
                label="Email Address"
                stretch={true}
                errorText={
                  form.fieldStates.email === "" || form.fieldStates.email
                    ? ""
                    : "Must be a valid email address."
                }
              >
                <Input
                  id="email"
                  value={form.fieldValues.email}
                  invalid={
                    !(form.fieldStates.email === ""
                      ? true
                      : form.fieldStates.email)
                  }
                  onInput={({ detail }) => {
                    setForm({
                      type: "email",
                      required: true,
                      payload: {
                        field: "email",
                        value: detail.value
                      }
                    });
                  }}
                />
              </FormField>
            </div>
          </ColumnLayout>
        </FormSection>
      </Form>
    </div>
  );
};

export default ResendConfirmationForm;
