/* eslint-disable react/display-name */
import React from "react";

function duration(start, End) {
  const startTime = new Date("01/01/2000 " + start);
  const endTime = new Date("01/01/2000 " + End);
  const diff = endTime - startTime;
  const resultInMinutes = Math.round(diff / 60000);
  return resultInMinutes.toString() + " mins";
}

export const AGENDA_COLUMN_DEFINITIONS = [
  {
    id: "time",
    header: () => "Time",
    cell: item => item.time,
    minWidth: "175px",
    allowLineWrap: true
  },
  {
    id: "duration",
    header: () => "Duration",
    cell: item => duration(item.time.split("-")[0], item.time.split("-")[1]),
    minWidth: "175px",
    allowLineWrap: true
  },
  {
    id: "module",
    header: () => "Module",
    cell: item => (
      <div>
        <h4>{item.heading}</h4>
        <h5>{item.subHeading}</h5>
      </div>
    ),
    minWidth: "200px",
    allowLineWrap: false
  },
  {
    id: "type",
    header: () => "Type",
    cell: item => item.type,
    minWidth: "200px",
    allowLineWrap: true
  },
  {
    id: "description",
    header: () => "Description",
    cell: item => item.description,
    minWidth: "200px",
    allowLineWrap: true
  }
];
