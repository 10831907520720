/* eslint-disable no-throw-literal */

import React, { useEffect, useState, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";

import moment from "moment";
import { API, graphqlOperation } from "aws-amplify";
import { getEvent } from "../../../graphql/queries";
import { updateEvent, getCsatSurveyResults } from "../../../graphql/mutations";
import { addFlash } from "../../../actions";
import { formReducer } from "../../../reducers";
import { immersionDayTopicOptions, geoOptions } from "../../../utils";

import {
  Alert,
  Button,
  ColumnLayout,
  DatePicker,
  Form,
  FormField,
  FormSection,
  Input,
  Select,
  Spinner,
  Textarea,
  TokenGroup,
  Toggle
} from "@amzn/awsui-components-react";

const DEFAULT_STATE = {
  fieldValues: {
    multiCustomer: false,
    customers: "",
    customersListInput: "",
    customersList: [],
    date: "",
    location: "",
    geo: "",
    topic: "",
    registrationCount: "",
    attendeeCount: "",
    comment: "",
    awspsa: "",
    oppty: "",
    markComplete: false,
    csat: {}
  },
  fieldStates: {
    customers: "",
    customersList: "",
    date: "",
    location: "",
    geo: "",
    topic: "",
    registrationCount: "",
    attendeeCount: ""
  },
  requiredFields: ["date", "location", "geo", "topic"]
};

const now = new Date();
const y = now.getFullYear();
const m = now.getMonth();
const d = now.getDate();
const yesterday = new Date(y, m, d - 1);

const formIsValidOverride = form => {
  let formErrors = 0;

  let key;

  for (key in form.fieldStates) {
    let keyIsRequired = form.requiredFields.indexOf(key) > -1 ? true : false;

    if (
      keyIsRequired &&
      (form.fieldStates[key] === false || form.fieldStates[key] === "")
    )
      formErrors += 1;
    if (form.fieldStates[key] === false) formErrors += 1;
  }

  let mc = form.fieldValues.multiCustomer;
  if (
    mc &&
    form.fieldValues.markComplete === true &&
    form.fieldValues.customersList.length === 0
  ) {
    console.error("Form error: No customers added to the multi-customer list");
    formErrors += 1;
  } else if (
    (!mc && form.fieldValues.customers === "") ||
    typeof form.fieldValues.customers !== "string"
  ) {
    console.error("Form error: No customers entered to the customer field");
    formErrors += 1;
  }

  return formErrors === 0 ? true : false;
};

const EditEventDetailForm = ({ eventId, history }) => {
  const currentUser = useSelector(state => state.app.currentUser);

  // Component states
  const [eventDetail, setEventDetail] = useState({});
  const [form, setForm] = useReducer(formReducer, DEFAULT_STATE);
  const [formErrorText, setFormErrorText] = useState(null);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);

  // Get data on page load
  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line

  const fetchData = async () => {
    setLoading(true);

    try {
      const result = await API.graphql(
        graphqlOperation(getEvent, { id: eventId })
      );
      const detail = result.data.getEvent;

      /**
       * THROW ERROR CONDITIONS
       */

      if (!detail) {
        throw {
          msg: "Event not found.",
          action: history.push("/events/calendar")
        };
      }

      if (detail.userId !== currentUser.id) {
        throw {
          msg: "Only event owners may modify an event.",
          action: history.push(`/events/${eventId}`)
        };
      }

      if (detail.status !== "Scheduled") {
        throw {
          msg: `Event is marked as ${detail.status} and can not be modified.`,
          action: history.push(`/events/${eventId}`)
        };
      }

      /**
       * CONTINUE IF NO ERRORS
       */

      const formInitState = {
        fieldValues: {
          multiCustomer: detail.multiCustomer,
          customers: detail.customers.length === 1 ? detail.customers[0] : "",
          customersListInput: "",
          customersList: detail.customers.map(val => ({ label: val })),
          date: detail.date,
          location: detail.location,
          geo: geoOptions.find(x => x.label === detail.geo),
          topic: immersionDayTopicOptions.find(x => x.label === detail.topic),
          registrationCount: detail.registrationCount
            ? detail.registrationCount.toString()
            : "",
          attendeeCount: detail.attendeeCount
            ? detail.attendeeCount.toString()
            : "",
          comment: detail.comment,
          awspsa: detail.awspsa,
          oppty: detail.oppty,
          markComplete: false,
          csat: detail.csat
        },
        fieldStates: {
          date: true,
          location: true,
          geo: true,
          topic: true,
          registrationCount: true,
          attendeeCount: true
        },
        requiredFields: ["date", "location", "geo", "topic"]
      };

      setEventDetail(detail);

      setForm({
        type: "initialize",
        payload: formInitState
      });
    } catch (err) {
      dispatch(
        addFlash({
          type: "error",
          content: err.msg
        })
      );
      if (err.action) err.action();
    }

    setLoading(false);
  };

  const dispatch = useDispatch();

  const submitForm = async () => {
    setSaving(true);
    let input = { ...form.fieldValues };
    const eventMarkedComplete = input.markComplete;

    try {
      // Add event id
      input.id = eventId;

      // Handle customers field
      let customers = [];
      if (!input.multiCustomer) customers.push(input.customers);
      else if (input.multiCustomer) {
        input.customersList.forEach(c => customers.push(c.label));
      }
      input.customers = customers;

      // Handle field types
      if (input.registrationCount === "") input.registrationCount = null;
      if (input.attendeeCount === "") input.attendeeCount = null;

      // Remove unwanted fields
      delete input.markComplete;
      delete input.customersList;
      delete input.customersListInput;

      // Handle select fields
      input.geo = input.geo.label;
      input.topic = input.topic.label;

      // Format the date field
      input.date = moment(input.date);

      // Some values can be empty strings. If they are, set them to null.
      input.awspsa = input.awspsa === "" ? null : input.awspsa;
      input.oppty = input.oppty === "" ? null : input.oppty;
      input.comment = input.comment === "" ? null : input.comment;

      // It could happen that an event is created and marked complete before a CSAT survey is generated
      // In that case, remove the csat record so it doesn't get updated to a null value before the survey is generated
      if (input.csat === null) delete input.csat;

      if (eventMarkedComplete) {
        input.status = "Complete";

        // Set the csat score to -1, which means we've kicked off the process to gather results.
        if (input.csat !== null) input.csat["score"] = -1;
      }

      // Save the profile to DynamoDB
      await API.graphql(graphqlOperation(updateEvent, { input }));

      // Event is complete, trigger action to gather CSAT results
      if (eventMarkedComplete) {
        const eventInfo = {
          eventId: eventDetail.id,
          surveyId: eventDetail.csat.surveyId,
          deliveryMethod: eventDetail.deliveryMethod,
          surveyType: eventDetail.csat.surveyType,
          qualtricsApiKeyParam: eventDetail.csat.qualtricsApiKeyParam
        };

        await API.graphql(
          graphqlOperation(getCsatSurveyResults, { input: eventInfo })
        );

        history.push(`/events/${eventId}`);
      } else {
        dispatch(
          addFlash({
            type: "success",
            content: "The event was successfully saved."
          })
        );
      }
    } catch (e) {
      dispatch(
        addFlash({
          type: "error",
          header: "An error occurred",
          content: "Please try again in a little while."
        })
      );
    }

    setSaving(false);
  };

  return (
    <div id="mainPanel">
      <Form
        header={
          <span>
            <h1>Edit event details</h1>
          </span>
        }
        errorText={formErrorText}
        actions={
          <div>
            {!loading && (
              <div>
                <Button
                  variant="link"
                  onClick={() => history.push(`/events/${eventId}`)}
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  loading={saving}
                  onClick={() => {
                    if (formIsValidOverride(form)) {
                      setFormErrorText("");
                      submitForm();
                    } else {
                      setFormErrorText(
                        <div>
                          Please fill out all of the required form fields.
                          <br />
                          <br />
                          For single-customer events, ensure the customer name
                          is provided.
                          <br />
                          <br />
                          For multi-customer events, at least one customer must
                          be provided before you are able to mark the event
                          complete.
                        </div>
                      );
                    }
                  }}
                >
                  Save
                </Button>
              </div>
            )}
          </div>
        }
      >
        <FormSection
          id="event-customer-form-panel"
          header={<h2>Event customer information</h2>}
        >
          <ColumnLayout>
            {loading && (
              <div data-awsui-column-layout-root={true}>
                <span className="awsui-util-status-inactive">
                  <Spinner /> Loading
                </span>
              </div>
            )}
            {!loading && (
              <div data-awsui-column-layout-root={true}>
                {/* Multi-Customer Toggle */}
                <FormField>
                  <Toggle
                    id="multiCustomer"
                    checked={form.fieldValues.multiCustomer}
                    onChange={({ detail }) => {
                      const toggleState = detail.checked;
                      if (toggleState) {
                        setForm({
                          type: "toggle",
                          payload: {
                            field: "multiCustomer",
                            value: detail
                          }
                        });
                        setForm({
                          type: "override",
                          payload: {
                            field: "customers",
                            value: "",
                            state: true
                          }
                        });
                      } else {
                        setForm({
                          type: "toggle",
                          payload: {
                            field: "multiCustomer",
                            value: detail
                          }
                        });
                        setForm({
                          type: "override",
                          payload: {
                            field: "customers",
                            value: "",
                            state: ""
                          }
                        });
                      }
                    }}
                  >
                    Multi-Customer?
                  </Toggle>
                </FormField>

                {/* Customer / Customers input */}
                {!form.fieldValues.multiCustomer && (
                  <FormField label="Customer">
                    <Input
                      id="customers"
                      value={form.fieldValues.customers}
                      onInput={({ detail }) => {
                        setForm({
                          type: "string",
                          required: true,
                          payload: {
                            field: "customers",
                            value: detail.value
                          }
                        });
                      }}
                    />
                  </FormField>
                )}
                {form.fieldValues.multiCustomer && (
                  <FormField
                    label="Customers"
                    description={
                      <span>
                        Use the <b>ENTER</b> or <b>RETURN</b> key to lock in a
                        value. Values are not saved unless they appear in the
                        token group below the input box.
                      </span>
                    }
                  >
                    <Input
                      id="customersListInput"
                      value={form.fieldValues.customersListInput}
                      onInput={({ detail }) => {
                        setForm({
                          type: "string",
                          payload: {
                            field: "customersListInput",
                            value: detail.value
                          }
                        });
                      }}
                      onChange={({ detail }) => {
                        setForm({
                          type: "addToken",
                          validation: "required",
                          payload: {
                            field: "customersList",
                            value: detail.value
                          }
                        });
                      }}
                    />
                    <TokenGroup
                      items={form.fieldValues.customersList}
                      onDismiss={({ detail }) => {
                        setForm({
                          type: "dismissToken",
                          payload: {
                            field: "customersList",
                            value: detail.itemIndex
                          }
                        });
                      }}
                    />
                  </FormField>
                )}

                {/* Registration Count Input */}
                <FormField
                  label={
                    <span>
                      Registration Count <i>- optional</i>
                    </span>
                  }
                  errorText={
                    form.fieldStates.registrationCount === "" ||
                    form.fieldStates.registrationCount
                      ? ""
                      : "This field must be a positive number."
                  }
                >
                  <Input
                    id="registrationCount"
                    value={form.fieldValues.registrationCount}
                    onInput={({ detail }) => {
                      setForm({
                        type: "positiveNumber",
                        payload: {
                          field: "registrationCount",
                          value: detail.value
                        }
                      });
                    }}
                  />
                </FormField>

                {/* Attendee Count Input */}
                <FormField
                  label={
                    <span>
                      Attendee Count <i>- optional</i>
                    </span>
                  }
                  errorText={
                    form.fieldStates.attendeeCount === "" ||
                    form.fieldStates.attendeeCount
                      ? ""
                      : "This field must be a positive number."
                  }
                >
                  <Input
                    id="attendeeCount"
                    value={form.fieldValues.attendeeCount}
                    onInput={({ detail }) => {
                      setForm({
                        type: "positiveNumber",
                        payload: {
                          field: "attendeeCount",
                          value: detail.value
                        }
                      });
                    }}
                  />
                </FormField>
              </div>
            )}
          </ColumnLayout>
        </FormSection>

        <FormSection
          id="event-delivery-form-panel"
          header={<h2>Event delivery information</h2>}
        >
          <ColumnLayout>
            {loading && (
              <div data-awsui-column-layout-root={true}>
                <span className="awsui-util-status-inactive">
                  <Spinner /> Loading
                </span>
              </div>
            )}
            {!loading && (
              <div data-awsui-column-layout-root={true}>
                {/* Event Date Input */}
                <FormField
                  label="Event Date"
                  errorText={
                    form.fieldStates.date === "" || form.fieldStates.date
                      ? ""
                      : "The event date is required and must not be in the past."
                  }
                >
                  <DatePicker
                    id="date"
                    placeholder="YYYY/MM/DD"
                    todayLabel="Today"
                    nextMonthLabel="Next month"
                    previousMonthLabel="Previous month"
                    isDateEnabled={date => date > yesterday}
                    value={form.fieldValues.date}
                    onInput={({ detail }) => {
                      setForm({
                        type: "date",
                        required: true,
                        payload: {
                          field: "date",
                          value: detail.value,
                          isAfter: yesterday
                        }
                      });
                    }}
                    onChange={({ detail }) => {
                      setForm({
                        type: "date",
                        required: true,
                        payload: {
                          field: "date",
                          value: detail.value,
                          isAfter: yesterday
                        }
                      });
                    }}
                  />
                </FormField>

                {/* Event Location Input */}
                <FormField
                  label="Event Location"
                  errorText={
                    form.fieldStates.location === "" ||
                    form.fieldStates.location
                      ? ""
                      : "This field is required."
                  }
                >
                  <Input
                    id="location"
                    placeholder="City, State, Country"
                    value={form.fieldValues.location}
                    onInput={({ detail }) => {
                      setForm({
                        type: "string",
                        required: true,
                        payload: {
                          field: "location",
                          value: detail.value
                        }
                      });
                    }}
                  />
                </FormField>

                {/* Event Geo Selector */}
                <FormField
                  label="Event Geo"
                  errorText={
                    form.fieldStates.geo === "" || form.fieldStates.geo
                      ? ""
                      : "This field is required."
                  }
                >
                  <Select
                    id="geo"
                    placeholder="Select the event geo"
                    options={geoOptions}
                    selectedOption={form.fieldValues.geo}
                    onChange={({ detail }) => {
                      setForm({
                        type: "object",
                        required: true,
                        payload: {
                          field: "geo",
                          value: detail.selectedOption
                        }
                      });
                    }}
                  />
                </FormField>

                {/* Immersion Day Topic Selector */}
                <FormField
                  label="Immersion Day Topic"
                  errorText={
                    form.fieldStates.topic === "" || form.fieldStates.topic
                      ? ""
                      : "This field is required."
                  }
                >
                  <Select
                    id="topic"
                    placeholder="Select the event topic"
                    options={immersionDayTopicOptions}
                    selectedOption={form.fieldValues.topic}
                    onChange={({ detail }) => {
                      setForm({
                        type: "object",
                        required: true,
                        payload: {
                          field: "topic",
                          value: detail.selectedOption
                        }
                      });
                    }}
                  />
                </FormField>

                {/* AWS PSA Input */}
                <FormField
                  label={
                    <span>
                      AWS Partner SA Name <i>- optional</i>
                    </span>
                  }
                >
                  <Input
                    id="awspsa"
                    value={form.fieldValues.awspsa}
                    onInput={({ detail }) => {
                      setForm({
                        type: "string",
                        payload: {
                          field: "awspsa",
                          value: detail.value
                        }
                      });
                    }}
                  />
                </FormField>
              </div>
            )}
          </ColumnLayout>
        </FormSection>

        <FormSection
          id="mark-complete-form-panel"
          header={<h2>Event completion</h2>}
        >
          <ColumnLayout>
            {loading && (
              <div data-awsui-column-layout-root={true}>
                <span className="awsui-util-status-inactive">
                  <Spinner /> Loading
                </span>
              </div>
            )}
            {!loading && (
              <div data-awsui-column-layout-root={true}>
                {/* Opportunity Link Textarea */}
                <FormField
                  label={
                    <span>
                      Opportunity Link(s) <i>- optional</i>
                    </span>
                  }
                  description="Add separate opportunities to their own line."
                >
                  <Textarea
                    id="oppty"
                    rows={3}
                    value={form.fieldValues.oppty}
                    onInput={({ detail }) => {
                      setForm({
                        type: "string",
                        payload: {
                          field: "oppty",
                          value: detail.value
                        }
                      });
                    }}
                  />
                </FormField>

                {/* Comments Textarea */}
                <FormField
                  label={
                    <span>
                      Comments <i>- optional</i>
                    </span>
                  }
                >
                  <Textarea
                    id="comment"
                    rows={3}
                    value={form.fieldValues.comment}
                    onInput={({ detail }) => {
                      setForm({
                        type: "string",
                        payload: {
                          field: "comment",
                          value: detail.value
                        }
                      });
                    }}
                  />
                </FormField>

                {/* Mark Complete Toggle */}
                <FormField
                  hintText={
                    <div>
                      {form.fieldValues.markComplete && (
                        <div>
                          <br />
                          <Alert type="warning">
                            Marking an event complete will disable editing of
                            the event in the future.
                          </Alert>
                        </div>
                      )}
                    </div>
                  }
                >
                  <Toggle
                    id="markComplete"
                    checked={form.fieldValues.markComplete}
                    onChange={({ detail }) => {
                      setForm({
                        type: "toggle",
                        payload: {
                          field: "markComplete",
                          value: detail
                        }
                      });
                    }}
                  >
                    Mark event as complete
                  </Toggle>
                </FormField>
              </div>
            )}
          </ColumnLayout>
        </FormSection>
      </Form>
    </div>
  );
};

export default EditEventDetailForm;
