import React from "react";
import { withRouter } from "react-router-dom";
import { SideNavigation } from "@amzn/awsui-components-react";
import { immersionDayTopicOptions } from "../../utils";

import logo from "../../img/AWS_APN_PartnerNetwork_ImmersionDay_Dark.png";
import "../../styles/navigation.scss";

class Navigation extends React.Component {
  // If the provided link is empty, do not redirect pages
  onFollowHandler(ev) {
    ev.preventDefault();
    if (ev.detail.href && ev.detail.external) {
      window.open(ev.detail.href, "_blank");
    } else if (ev.detail.href) {
      this.props.history.push(ev.detail.href);
    }
  }

  render() {
    return (
      <div>
        <img
          className="nav-logo"
          src={logo}
          alt="APN Immersion Day"
          onClick={() => this.props.history.push("/")}
        />
        <SideNavigation
          items={items}
          activeHref={`${this.props.location.pathname}`}
          onFollow={this.onFollowHandler.bind(this)}
        />
      </div>
    );
  }
}

const externalHrefs = {
  programPage: "https://aws.amazon.com/partners/immersion-days/"
};

const items = [
  { type: "divider" },
  {
    type: "link",
    text: "Public Program Page",
    href: externalHrefs.programPage,
    external: true
  },
  { type: "divider" },
  { type: "link", text: "My Profile", href: "/user-profile" },
  { type: "link", text: "My Organization", href: "/organization" },
  { type: "divider" },
  {
    type: "section",
    expanded: false,
    text: "Immersion Days",
    items: immersionDayTopicOptions.map(topic => ({
      type: "link",
      text: topic.label,
      href: `/courses/${topic.id}`
    }))
  },
  {
    type: "section",
    expanded: false,
    text: "Event Management",
    items: [
      { type: "link", text: "Schedule an Event", href: "/events/schedule" },
      { type: "link", text: "Event Calendar", href: "/events/calendar" },
      { type: "link", text: "My Scheduled Events", href: "/events/scheduled" },
      { type: "link", text: "My Complete Events", href: "/events/complete" }
    ]
  },
  {
    type: "section",
    expanded: false,
    text: "Resources",
    items: [
      { type: "link", text: "Training", href: "/resources/training" },
      { type: "link", text: "Marketing", href: "/resources/marketing" },
      { type: "link", text: "Funding", href: "/resources/funding" },
      { type: "link", text: "FAQ", href: "/resources/faq" },
      {
        type: "link",
        text: "Virtual Event Guidance",
        href: "/resources/virtual-guidance"
      }
    ]
  },
  { type: "divider" },
  { type: "link", text: "Leave Feedback", href: "/feedback" },
  { type: "divider" },
  { type: "link", text: "Logout", href: "/auth/logout" }
];

export default withRouter(Navigation);
