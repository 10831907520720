import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { updateBreadCrumbs } from "../../actions";

import ScheduledEventsTable from "./components/ScheduledEventsTable";

import "../../styles/eventStyles.scss";

const ScheduledEventsPage = props => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      updateBreadCrumbs([
        {
          text: "APN Immersion Day",
          href: "/"
        },
        {
          text: "My Scheduled Events",
          href: "/events/scheduled"
        }
      ])
    );
  }, [dispatch]);

  return (
    <>
      <ScheduledEventsTable history={props.history} />
    </>
  );
};

export default ScheduledEventsPage;
