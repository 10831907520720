import React from "react";

export const AWSTEAM_COLUMN_DEFINITIONS = [
  {
    id: "role",
    header: () => "Role",
    cell: item => item.role,
    minWidth: "35px",
    allowLineWrap: false
  },
  {
    id: "name",
    header: () => "Name",
    cell: item => item.name,
    minWidth: "150px",
    allowLineWrap: true
  },
  {
    id: "email",
    header: () => "Email",
    cell: item => <a href={`mailto:${item.email}`}>{item.email}</a>, // eslint-disable-line react/display-name
    minWidth: "150px",
    allowLineWrap: true
  },
  {
    id: "phoneno",
    header: () => "Phone",
    cell: item => item.phoneno,
    minWidth: "80px",
    allowLineWrap: false
  }
];
