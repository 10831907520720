import React, { useReducer } from "react";

import { formReducer } from "../../../reducers";

import {
  Button,
  Checkbox,
  ColumnLayout,
  Form,
  FormField,
  Input,
  Modal,
  Select
} from "@amzn/awsui-components-react";

const validator = require("validator");

const DEFAULT_STATE = {
  fieldValues: {
    newUserEmail: "",
    emailDomain: [],
    confirm1: false,
    confirm2: false,
    confirm3: false,
    confirm4: false,
    confirm5: false
  },
  fieldStates: {
    newUserEmail: "",
    emailDomain: "",
    confirm1: false,
    confirm2: false,
    confirm3: false,
    confirm4: false,
    confirm5: false
  },
  requiredFields: ["newUserEmail", "emailDomain"]
};

// New user invitation form
const InviteNewUserModal = ({ emailDomains, onCancel, onSubmit }) => {
  const [form, setForm] = useReducer(formReducer, DEFAULT_STATE);

  const domainOptions = () => {
    let options = [];
    for (let i = 0; i < emailDomains.length; i += 1) {
      let domain = emailDomains[i];
      options.push({
        id: i.toString(),
        label: domain
      });
    }
    return options;
  };

  const formIsValid = () => {
    if (
      form.fieldValues.emailDomain.length === 0 ||
      form.fieldValues.newUserEmail === "" ||
      !form.fieldStates.confirm1 ||
      !form.fieldStates.confirm2 ||
      !form.fieldStates.confirm3 ||
      !form.fieldStates.confirm4 ||
      !form.fieldStates.confirm5
    )
      return false;
    else {
      const checkEmail = `${form.fieldValues.newUserEmail}${form.fieldValues.emailDomain.label}`;
      return validator.isEmail(checkEmail) ? true : false;
    }
  };

  return (
    <Modal
      header="Invite new user"
      visible={true}
      expandToFit={true}
      footer={
        <span className="awsui-util-f-r">
          <Button variant="link" onClick={() => onCancel()}>
            Cancel
          </Button>
          <Button
            variant="primary"
            disabled={!formIsValid()}
            onClick={() => {
              if (formIsValid()) onSubmit(form.fieldValues);
            }}
          >
            Send Invitation
          </Button>
        </span>
      }
    >
      <div>
        <h4>
          In order to nominate a new practitioner from your organization, please complete the following checklist along with the practitioner's email address.
          The new practitioner will receive an invitation to access the APN Immersion Day Partner Portal and complete their profile.
        </h4>
        <br /><hr /><br />
        <Form>
          <FormField stretch={true}>
            <ColumnLayout columns={1}>
              <div data-awsui-column-layout-root="true">
                <FormField stretch={true}>
                  <Checkbox
                    checked={form.fieldValues.confirm1}
                    onChange={({ detail }) => {
                      setForm({
                        type: "checkbox",
                        required: true,
                        payload: {
                          field: "confirm1",
                          value: detail
                        }
                      });
                    }}
                  >
                    I have reviewed all content from the Train the Trainer (TTT) modules with this new practitioner
                  </Checkbox>
                </FormField>
                <FormField stretch={true}>
                  <Checkbox
                    checked={form.fieldValues.confirm2}
                    onChange={({ detail }) => {
                      setForm({
                        type: "checkbox",
                        required: true,
                        payload: {
                          field: "confirm2",
                          value: detail
                        }
                      });
                    }}
                  >
                    I have shared delivery best practices with this new practitioner
                  </Checkbox>
                </FormField>
                <FormField stretch={true}>
                  <Checkbox
                    checked={form.fieldValues.confirm3}
                    onChange={({ detail }) => {
                      setForm({
                        type: "checkbox",
                        required: true,
                        payload: {
                          field: "confirm3",
                          value: detail
                        }
                      });
                    }}
                  >
                    I have reviewed program resources including training, funding and marketing with this new practitioner
                  </Checkbox>
                </FormField>
                <FormField stretch={true}>
                  <Checkbox
                    checked={form.fieldValues.confirm4}
                    onChange={({ detail }) => {
                      setForm({
                        type: "checkbox",
                        required: true,
                        payload: {
                          field: "confirm4",
                          value: detail
                        }
                      });
                    }}
                  >
                    The new practitioner has shadowed at least one Immersion Day with a trained practitioner or an AWS Solution Architect
                  </Checkbox>
                </FormField>
                <FormField stretch={true}>
                  <Checkbox
                    checked={form.fieldValues.confirm5}
                    onChange={({ detail }) => {
                      setForm({
                        type: "checkbox",
                        required: true,
                        payload: {
                          field: "confirm5",
                          value: detail
                        }
                      });
                    }}
                  >
                    I certify that this individual has been trained on the APN Immersion Day program guidelines
                  </Checkbox>
                </FormField>
              </div>
            </ColumnLayout>
            <br /><hr /><br />
            <ColumnLayout columns={2}>
              <div data-awsui-column-layout-root="true">
                <FormField label="Email Alias" description="Alias only." stretch={true}>
                  <Input
                    id="newUserEmail"
                    placeholder="User email alias"
                    value={form.fieldValues.newUserEmail}
                    onInput={({ detail }) =>
                      setForm({
                        type: "string",
                        payload: {
                          field: "newUserEmail",
                          required: true,
                          value: detail.value
                        }
                      })
                    }
                  />
                </FormField>
                <FormField
                  label="Email Domain"
                  description="Select the domain to append."
                  stretch={true}
                >
                  <Select
                    options={domainOptions()}
                    selectedOption={form.fieldValues.emailDomain}
                    ariaRequired={true}
                    onChange={({ detail }) =>
                      setForm({
                        type: "select",
                        payload: {
                          field: "emailDomain",
                          required: true,
                          value: detail.selectedOption
                        }
                      })
                    }
                  />
                </FormField>
              </div>
            </ColumnLayout>
          </FormField>
        </Form>
      </div>
    </Modal>
  );
};

export default InviteNewUserModal;
