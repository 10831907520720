import React, { useEffect, useState } from "react";

import { loadMarkdownFromS3 } from "../../../utils";

import { Spinner } from "@amzn/awsui-components-react";
import Markdown from "react-markdown";

const rootPath = "home/markdown";
const contentPath = "WhatsNew2.md";

const RenderMarkdown = ({ loading, md }) => {
  if (loading) {
    return (
      <span className="awsui-util-status-inactive">
        <Spinner /> Loading
      </span>
    );
  } else return <Markdown>{md}</Markdown>;
};

const WhatsNew = () => {
  const [md, setMd] = useState("");
  const [loading, setLoading] = useState(false);

  // Markdown loader
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setMd(await loadMarkdownFromS3(`${rootPath}/${contentPath}`));
      setLoading(false);
    };
    fetchData();
  }, []);

  return (
    <div className="awsui-util-container">
      <div className="awsui-util-container-header">
        <h2>What&apos;s New</h2>
      </div>
      <div>
        <RenderMarkdown loading={loading} md={md} />
      </div>
    </div>
  );
};

export default WhatsNew;
