import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { updateBreadCrumbs } from "../../actions";

import RegistrationForm from "./components/RegistrationForm";

const RegistrationPage = props => {
  const { history } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateBreadCrumbs([{}]));
  }, [dispatch]);

  return <RegistrationForm history={history} />;
};

export default RegistrationPage;
