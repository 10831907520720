import produce from "immer";
import { objIsEmpty } from "../utils";

const validator = require("validator");

function formatDate(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("/");
}

const formReducer = (form, action) => {
  if (action.type === "initialize") return action.payload;

  const field = action.payload.field;
  const value = action.payload.value;
  let validState;

  return produce(form, draft => {
    switch (action.type) {
      case "string":
        if (action.required) {
          if (typeof value === "string" && value.trim() !== "")
            validState = !validator.isEmpty(value) ? true : false;
          else validState = false;

          draft.fieldStates[field] = validState;
        }
        draft.fieldValues[field] = value.trim();
        break;

      case "positiveNumber":
        if (value === "") validState = true;
        else {
          validState = validator.isInt(value, {
            min: 1
          })
            ? true
            : false;
        }
        draft.fieldStates[field] = validState;
        draft.fieldValues[field] = value.trim();
        break;

      case "object":
        if (action.required) {
          if (typeof value === "object")
            validState = !objIsEmpty(value) ? true : false;
          else validState = false;

          draft.fieldStates[field] = validState;
        }
        draft.fieldValues[field] = value;
        break;

      case "phone":
        validState =
          validator.isMobilePhone(value.replace(/\s/g, ""), "any", {
            strictMode: false
          }) || value === ""
            ? true
            : false;
        draft.fieldStates[field] = validState;
        draft.fieldValues[field] = value;
        break;

      case "email":
        if (action.required) {
          if (typeof value === "string")
            validState = validator.isEmail(value) ? true : false;
          else validState = false;

          draft.fieldStates[field] = validState;
        }
        draft.fieldValues[field] = value;
        break;

      case "date":
        validState = validator.isDate(value, "YYYY/MM/DD") ? true : false;
        if (action.payload.isAfter) {
          validState = validator.isAfter(
            value,
            formatDate(action.payload.isAfter)
          );
        }
        draft.fieldStates[field] = validState;
        draft.fieldValues[field] = value;
        break;

      case "checkbox":
        if (action.required) {
          validState = value.checked;
          draft.fieldStates[field] = validState;
        }
        draft.fieldValues[field] = value.checked;
        break;

      case "toggle":
        if (action.required) {
          validState = value.checked;
          draft.fieldStates[field] = validState;
        }
        draft.fieldValues[field] = value.checked;
        break;

      case "select":
        if (action.payload.required)
          draft.fieldStates[field] = !objIsEmpty(value) ? true : false;
        draft.fieldValues[field] = value;
        break;

      case "password":
        if (action.required) {
          if (typeof value === "string")
            validState = !validator.isEmpty(value) ? true : false;
          else validState = false;

          draft.fieldStates[field] = validState;
        }
        if (action.payload.matches) {
          draft.fieldStates[action.payload.matches] =
            value === draft.fieldValues[action.payload.matches] ? true : false;
        }
        draft.fieldValues[field] = value;
        break;

      case "confirmPassword":
        if (action.required) {
          if (typeof value === "string")
            validState =
              value === draft.fieldValues[action.payload.matches]
                ? true
                : false;
          else validState = false;

          draft.fieldStates[field] = validState;
        }
        draft.fieldValues[field] = value;
        break;

      case "addToken":
        let addTokenList = [...form.fieldValues[field]];
        addTokenList.push({
          label: value
        });
        let addTokenInputKey = `${field}Input`;

        if (action.required) {
          validState = addTokenList.length >= 1 ? true : false;
          draft.fieldStates[field] = validState;
        }

        draft.fieldValues[field] = addTokenList;
        draft.fieldValues[addTokenInputKey] = "";
        break;

      case "dismissToken":
        let dismissTokenList = [...form.fieldValues[field]];
        dismissTokenList.splice(value, 1);

        if (action.required) {
          validState = dismissTokenList.length >= 1 ? true : false;
          draft.fieldStates[field] = validState;
        }
        draft.fieldValues[field] = dismissTokenList;
        break;

      case "override":
        draft.fieldStates[field] = action.payload.state;
        draft.fieldValues[field] = value;
        break;

      case "reset":
      default:
        draft = action.payload;
        break;
    }
  });
};

export default formReducer;
