import React from 'react';

import { useHistory } from 'react-router-dom';

import './AppFooter.scss';

const year = new Date().getFullYear();

export const AppFooter = () => {
    const history = useHistory();
    return (
        <div id="f">
            <div id="console-nav-footer" className="console-nav-footer">
                <span className="footer-container-left">
                    <span
                        className="footer-link"
                        onClick={() => history.push('/feedback')}>
                        Feedback
                    </span>
                </span>

                <span className="footer-container-right">
                    <span className="copyright-notice">
                        © 2008 - {year}, Amazon Web Services, Inc. or its
                        affiliates. All rights reserved.
                    </span>
                    <span
                        className="footer-link"
                        onClick={() =>
                            window.open(
                                'http://aws.amazon.com/privacy/',
                                '_blank'
                            )
                        }>
                        Privacy policy
                    </span>
                    <span
                        className="footer-link"
                        onClick={() =>
                            window.open(
                                'http://aws.amazon.com/terms/',
                                '_blank'
                            )
                        }>
                        Terms of use
                    </span>
                </span>
            </div>
        </div>
    );
};
