import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Button,
  Table,
  TableContentSelector,
  TableFiltering,
  TablePageSizeSelector,
  TablePagination,
  TablePreferences,
  TableSelection,
  TableSorting,
  TableWrapLines
} from "@amzn/awsui-components-react";

import {
  COLUMN_DEFINITIONS,
  CONTENT_SELECTOR_OPTIONS,
  PAGE_SELECTOR_OPTIONS,
  SORTABLE_COLUMNS
} from "./ScheduledEventsTableConfig.jsx";

import { API, graphqlOperation } from "aws-amplify";
import { listEventsByUserByDate } from "../../../graphql/apnid-queries";
import { cancelEvent } from "../../../graphql/mutations";

import { addFlash } from "../../../actions";

const ScheduledEventsTable = ({ history }) => {
  const currentUser = useSelector(state => state.app.currentUser);
  const userId = currentUser.id;

  const [loading, setLoading] = useState(false);
  const [scheduledEvents, setScheduledEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState([]);
  const [pageSize, setPageSize] = useState(30);
  const [filteringText, setFilteringText] = useState("");
  const [cancelEventLoading, setCancelEventLoading] = useState(false);

  const dispatch = useDispatch();

  // Get data on page load
  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line

  // Fetch data from GraphQL API
  const fetchData = async () => {
    setLoading(true);
    const filter = { status: { eq: "Scheduled" } };
    const result = await API.graphql(
      graphqlOperation(listEventsByUserByDate, {
        userId,
        limit: 100000,
        filter
      })
    );
    setScheduledEvents(result.data.eventsByUserByDate.items);
    setLoading(false);
  };

  // Updates the page size in preferences
  const onPaginationChange = ({ detail: { pageSize } }) => {
    setPageSize(pageSize);
  };

  // Updates the filtering text
  const onFilteringChange = ({ detail: { filteringText } }) => {
    setFilteringText(filteringText);
  };

  // Resets the filtering text
  const clearFilter = () => {
    setFilteringText("");
  };

  // Sets the selected event row
  const onSelectedEventChange = event => {
    setSelectedEvent(event.detail.selectedItems);
  };

  // Cancel the selected event
  const cancelSelectedEvent = () => {
    setCancelEventLoading(true);

    if (selectedEvent.length) {
      selectedEvent.forEach(async row => {
        try {
          await API.graphql(
            graphqlOperation(cancelEvent, {
              input: {
                eventId: row.id,
                surveyId: row.csat.surveyId,
                qualtricsApiKeyParam: row.csat.qualtricsApiKeyParam
              }
            })
          );
          fetchData();
          dispatch(
            addFlash({
              type: "success",
              content:
                "Event cancelation accepted. The record will be removed from view shortly."
            })
          );
        } catch (err) {
          dispatch(
            addFlash({
              type: "error",
              header: "An error occurred.",
              content: err.errors[0].message
            })
          );
        }
      });
    }

    setCancelEventLoading(false);
  };

  return (
    <Table
      columnDefinitions={COLUMN_DEFINITIONS(history)}
      items={scheduledEvents}
      loading={loading}
      loadingText="Loading..."
      header={
        <ScheduledEventsTableHeader
          refreshFn={fetchData}
          selectedEvent={selectedEvent}
          cancelState={cancelEventLoading}
          cancelFn={cancelSelectedEvent}
        />
      }
      empty={
        <div className="awsui-util-t-c">
          <div className="awsui-util-pt-s awsui-util-mb-xs">
            <b>No events</b>
          </div>
          <p className="awsui-util-mb-s">No scheduled events to display.</p>
        </div>
      }
      noMatch={
        <div className="awsui-util-t-c">
          <div className="awsui-util-pt-xs awsui-util-mb-xs">
            <b>No matches</b>
          </div>
          <p className="awsui-util-mb-s">No results match your query</p>
          <div className="awsui-util-mb-l">
            <Button onClick={clearFilter.bind(this)}>Clear filter</Button>
          </div>
        </div>
      }
    >
      <TableFiltering
        filteringPlaceholder="Search scheduled events"
        filteringText={filteringText}
        onFilteringChange={onFilteringChange.bind(this)}
      />
      <TablePagination
        onPaginationChange={onPaginationChange.bind(this)}
        pageSize={pageSize}
      />
      <TableSorting
        sortingColumn="date"
        sortingDescending={false}
        sortableColumns={SORTABLE_COLUMNS}
      />
      <TableSelection
        selectionType="single"
        selectedItems={selectedEvent}
        onSelectionChange={onSelectedEventChange.bind(this)}
      />
      <TablePreferences
        title="Preferences"
        confirmLabel="Confirm"
        cancelLabel="Cancel"
      >
        <TablePageSizeSelector
          title="Page size"
          options={PAGE_SELECTOR_OPTIONS}
        />
        <TableWrapLines
          label="Wrap lines"
          description="Check to see all the text and wrap the lines"
          value={false}
        />
        <TableContentSelector
          title="Select visible columns"
          options={CONTENT_SELECTOR_OPTIONS}
        />
      </TablePreferences>
    </Table>
  );
};

const ScheduledEventsTableHeader = ({
  selectedEvent,
  refreshFn,
  cancelState,
  cancelFn
}) => {
  return (
    <div className="awsui-util-action-stripe">
      <div className="awsui-util-action-stripe-title">
        <h2>My Scheduled Events</h2>
      </div>
      <div className="awsui-util-action-stripe-group">
        <Button icon="refresh" onClick={() => refreshFn()} />
        <Button
          icon="close"
          loading={cancelState}
          disabled={selectedEvent.length === 0}
          onClick={() => cancelFn()}
        >
          Cancel Event
        </Button>
      </div>
    </div>
  );
};

export default ScheduledEventsTable;
