import React, { useState, useReducer } from "react";
import { useSelector } from "react-redux";

import { Auth as AmplifyAuth } from "aws-amplify";
import { formReducer } from "../../../reducers";
import { formIsValid } from "../../../utils";

import logo from "../../../img/AWS_APN_PartnerNetwork_ImmersionDay_Dark.png";
import "../../../styles/auth.scss";

import {
  Button,
  ColumnLayout,
  Form,
  FormField,
  FormSection,
  Input,
  Modal
} from "@amzn/awsui-components-react";

const DEFAULT_STATE = {
  fieldValues: {
    email: "",
    verificationCode: "",
    newPassword: "",
    confirmNewPassword: ""
  },
  fieldStates: {
    email: "",
    verificationCode: "",
    newPassword: "",
    confirmNewPassword: ""
  },
  requiredFields: [
    "email",
    "verificationCode",
    "newPassword",
    "confirmNewPassword"
  ]
};

const AlertModal = ({ onClose }) => {
  return (
    <Modal
      header="Success!"
      visible={true}
      footer={
        <span className="awsui-util-f-r">
          <Button variant="primary" onClick={() => onClose()}>
            Ok
          </Button>
        </span>
      }
    >
      <p>
        Password successfully changed. Please log in with your new credentials.
      </p>
    </Modal>
  );
};

const ChangePasswordForm = ({ history }) => {
  // Check if user is alread logged in and redirect to home
  const currentUser = useSelector(state => state.app.currentUser);
  if (currentUser) history.push("/");

  // Component states
  const [form, setForm] = useReducer(formReducer, DEFAULT_STATE);
  const [formErrorText, setFormErrorText] = useState(null);
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(null);

  const submitForm = async () => {
    setLoading(true);
    let input = { ...form.fieldValues };

    try {
      await AmplifyAuth.forgotPasswordSubmit(
        input.email,
        input.verificationCode,
        input.newPassword
      );
      setModal(<AlertModal onClose={() => history.push("/auth/login")} />);
    } catch (e) {
      let errMsg = "";

      if (e.code === "InvalidParameterException") {
        errMsg = "The new password does not satisfy policy requirements.";
      } else {
        errMsg =
          "The email address or verification code is invalid. Please try again.";
      }

      setFormErrorText(errMsg);
      setLoading(false);
    }
  };

  return (
    <div id="mainPanel" className="auth-form">
      {modal}
      <Form
        header={
          <img
            src={logo}
            className="auth-logo"
            alt="APN Immersion Day Change Password Form"
          />
        }
        errorText={formErrorText}
        actions={
          <div>
            <Button
              variant="primary"
              loading={loading}
              onClick={() => {
                if (formIsValid(form)) {
                  setFormErrorText("");
                  submitForm();
                } else {
                  setFormErrorText(
                    "Please fill out all of the required form fields."
                  );
                }
              }}
            >
              Submit
            </Button>
          </div>
        }
      >
        <FormSection id="change-password-panel" header="Change password">
          <ColumnLayout>
            <div data-awsui-column-layout-root={true}>
              <FormField
                label="Email Address"
                stretch={true}
                errorText={
                  form.fieldStates.email === "" || form.fieldStates.email
                    ? ""
                    : "Must be a valid email address."
                }
              >
                <Input
                  id="email"
                  value={form.fieldValues.email}
                  onInput={({ detail }) => {
                    setForm({
                      type: "email",
                      required: true,
                      payload: {
                        field: "email",
                        value: detail.value
                      }
                    });
                  }}
                />
              </FormField>
              <FormField
                label="Verification Code"
                stretch={true}
                errorText={
                  form.fieldStates.verificationCode === "" ||
                  form.fieldStates.verificationCode
                    ? ""
                    : "This field is required."
                }
              >
                <Input
                  id="verificationCode"
                  value={form.fieldValues.verificationCode}
                  onInput={({ detail }) => {
                    setForm({
                      type: "string",
                      required: true,
                      payload: {
                        field: "verificationCode",
                        value: detail.value
                      }
                    });
                  }}
                />
              </FormField>
              <FormField
                label="New Password"
                stretch={true}
                errorText={
                  form.fieldStates.newPassword === "" ||
                  form.fieldStates.newPassword
                    ? ""
                    : "This field is required."
                }
              >
                <Input
                  id="newPassword"
                  type="password"
                  value={form.fieldValues.newPassword}
                  onInput={({ detail }) => {
                    setForm({
                      type: "password",
                      required: true,
                      payload: {
                        field: "newPassword",
                        value: detail.value,
                        matches: "confirmNewPassword"
                      }
                    });
                  }}
                />
              </FormField>
              <FormField
                label="Confirm New Password"
                stretch={true}
                errorText={
                  form.fieldStates.confirmNewPassword === "" ||
                  form.fieldStates.confirmNewPassword
                    ? ""
                    : "The passwords do not match."
                }
              >
                <Input
                  id="confirmNewPassword"
                  type="password"
                  value={form.fieldValues.confirmNewPassword}
                  onInput={({ detail }) => {
                    setForm({
                      type: "confirmPassword",
                      required: true,
                      payload: {
                        field: "confirmNewPassword",
                        value: detail.value,
                        matches: "newPassword"
                      }
                    });
                  }}
                />
              </FormField>
            </div>
          </ColumnLayout>
        </FormSection>
      </Form>
    </div>
  );
};

export default ChangePasswordForm;
