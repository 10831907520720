/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { addFlash, addBreadCrumb, updateBreadCrumbs } from "../../actions";
import { handleDownload, loadYamlFromS3 } from "../../utils";
import { Storage } from "aws-amplify";
import {
  Button,
  ExpandableSection,
  Icon,
  Spinner,
  Tabs
} from "@amzn/awsui-components-react";

import Markdown from "react-markdown";
import ReactPlayer from "react-player";

import AgendaTable from "./components/AgendaTable";
import CourseTable from "./components/CourseTable";

const CoursePage = props => {
  const { match } = props;
  const { course } = match.params;
  const contentPath = `${course}/_config.yaml`;

  const dispatch = useDispatch();

  const [courseConfig, setCourseConfig] = useState(false);
  const [mediaLink, setMediaLink] = useState(false);
  const [loading, setLoading] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [activeTabId, setActiveTabId] = useState("overview");

  // Breadcrumbs
  useEffect(() => {
    dispatch(
      updateBreadCrumbs([
        {
          text: "APN Immersion Day",
          href: "/"
        }
      ])
    );
  }, [dispatch, course]);

  // Config loader
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      try {
        const result = await loadYamlFromS3(contentPath);

        dispatch(
          addBreadCrumb({
            text: result.header,
            href: `/courses/${course}`
          })
        );

        if (result.bestpractices) {
          setMediaLink(
            await Storage.get(`${course}/${result.bestpractices.source}`, {
              expires: 5400
            })
          );
        }

        setActiveTabId("overview");
        setNotFound(false);
        setCourseConfig(result);
      } catch (err) {
        setNotFound(true);
        dispatch(
          addFlash({
            type: "error",
            content: `Unable to load course: ${course}`
          })
        );
      }

      setLoading(false);
    };
    fetchData();
  }, [dispatch, course, contentPath]);

  // Page tabs
  let tabs = [];
  if (courseConfig && courseConfig.details) {
    tabs.push({
      label: "Overview",
      id: "overview",
      content: <Markdown className="md" source={courseConfig.details} />
    });
  }

  if (courseConfig && courseConfig.bestpractices) {
    tabs.push({
      label: "Tips & Tricks",
      id: "tips",
      content: (
        <ReactPlayer
          url={mediaLink}
          controls={true}
          width="100%"
          height="100%"
        />
      )
    });
  }

  if (courseConfig && courseConfig.prechecklist) {
    tabs.push({
      label: "Pre Checklist",
      id: "prechecklist",
      content: <Markdown className="md" source={courseConfig.prechecklist} />
    });
  }

  if (courseConfig && courseConfig.postchecklist) {
    tabs.push({
      label: "Post Checklist",
      id: "postchecklist",
      content: <Markdown className="md" source={courseConfig.postchecklist} />
    });
  }

  if (courseConfig && courseConfig.modules) {
    tabs.push({
      label: "Modules",
      id: "modules",
      content: courseConfig.modules.map(section => (
        <ExpandableSection
          key={Object.keys(section)[0]}
          header={Object.keys(section)[0]}
          expanded={courseConfig.modules.length > 1 ? false : true}
        >
          <CourseTable
            course={course}
            type="modules"
            items={section[Object.keys(section)[0]]}
          />
        </ExpandableSection>
      ))
    });
  }

  if (courseConfig && courseConfig.labs) {
    tabs.push({
      label: "Labs",
      id: "labs",
      content: courseConfig.labs.map(section => (
        <ExpandableSection
          key={Object.keys(section)[0]}
          header={Object.keys(section)[0]}
          expanded={courseConfig.labs.length > 1 ? false : true}
        >
          <CourseTable
            course={course}
            type="labs"
            items={section[Object.keys(section)[0]]}
          />
        </ExpandableSection>
      ))
    });
  }

  if (courseConfig && courseConfig.agendas) {
    tabs.push({
      label: courseConfig.agendas.length > 1 ? "Agendas" : "Agenda",
      id: "agendas",
      content: courseConfig.agendas.map(section => (
        <ExpandableSection
          key={Object.keys(section)[0]}
          header={Object.keys(section)[0]}
          expanded={courseConfig.agendas.length > 1 ? false : true}
        >
          <AgendaTable items={section[Object.keys(section)[0]]} />
        </ExpandableSection>
      ))
    });
  }

  if (loading) {
    return (
      <div className="awsui-util-container">
        <div>
          <span>
            <Spinner /> Loading ...
          </span>
        </div>
      </div>
    );
  } else if (!loading && notFound) {
    return (
      <div className="awsui-util-container">
        <div>
          <span className="awsui-util-status-negative">
            <Icon name="status-negative" />
          </span>{" "}
          Unable to load course: {course}
        </div>
      </div>
    );
  } else if (!loading && !notFound) {
    return (
      <div className="awsui-util-container">
        <div className="awsui-util-container-header">
          <div className="awsui-util-action-stripe">
            <div className="awsui-util-action-stripe-title">
              <h2>{courseConfig.header}</h2>
            </div>
            <div className="awsui-util-action-stripe-group">
              <Button
                variant="primary"
                icon="download"
                onClick={event =>
                  handleDownload(event, `${course}/${course}.zip`)
                }
              >
                Download
              </Button>
            </div>
          </div>
        </div>
        <div>
          <Tabs
            tabs={tabs}
            activeTabId={activeTabId}
            onChange={({ detail }) => setActiveTabId(detail.activeTabId)}
          />
        </div>
      </div>
    );
  }
};

export default CoursePage;
