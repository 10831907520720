import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  setCurrentUser,
  setUserIsAuthenticated,
  setUserIsAuthenticating,
  userReset,
  removeFlash
} from "./actions";

import AppFooter from "./components/AppFooter";
import Navigation from "./components/Navigation";
import AppRoutes from "./AppRoutes";
import {
  AppLayout,
  BreadcrumbGroup,
  Flashbar,
  Spinner
} from "@amzn/awsui-components-react";

import { Auth as AmplifyAuth } from "aws-amplify";
import { fetchUserProfile, shortbread } from "./utils";

const App = () => {
  const dispatch = useDispatch();

  // Set auth vars on load
  useEffect(() => {
    async function determineSession() {
      try {
        if (await AmplifyAuth.currentSession()) {
          const user = await AmplifyAuth.currentAuthenticatedUser();
          dispatch(setCurrentUser(await fetchUserProfile(user)));
          dispatch(setUserIsAuthenticated(true));
          dispatch(setUserIsAuthenticating(false));
        }
      } catch (e) {
        dispatch(userReset());
        dispatch(setUserIsAuthenticating(false));
        if (e !== "No current user") {
        }
      }
    }
    determineSession();
  }, [dispatch]);

  shortbread();

  const userIsAuthenticated = useSelector(
    state => state.app.userIsAuthenticated
  );
  const userIsAuthenticating = useSelector(
    state => state.app.userIsAuthenticating
  );
  const breadCrumbs = useSelector(state => state.app.breadcrumbItems);
  const flashMessages = useSelector(state => state.app.flashbarItems);

  const displayFlashMessages = useMemo(() => {
    return flashMessages.map(f => ({
      ...f,
      dismiss: () => dispatch(removeFlash([f.id]))
    }));
  }, [dispatch, flashMessages]);

  if (userIsAuthenticating) {
    return (
      <span aria-label="Loading table content.">
        <Spinner size="large" />
      </span>
    );
  } else {
    return (
      <div>
        <AppLayout
          content={<AppRoutes />}
          navigation={<Navigation activeHref={window.location.pathname} />}
          navigationHide={!userIsAuthenticated ? true : false}
          contentType="default"
          toolsHide={true}
          notifications={<Flashbar items={displayFlashMessages} />}
          breadcrumbs={<BreadcrumbGroup items={breadCrumbs} />}
          footerSelector="#f"
        />
        <AppFooter />
      </div>
    );
  }
};

export default App;
